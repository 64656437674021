import { useSelector, useDispatch } from 'react-redux';
import { updateLayer } from '@carto/react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from 'utils/htmlForFeature';
import { useCallback } from 'react';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const TOPSPORT_LAYER_ID = 'topsportLayer';

const defaultTopsportLayerConfig = {
  id: TOPSPORT_LAYER_ID,
  title: 'Top 15 Sports Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: [],
    colors: colorScheme["TopSportsDefault"]
  }
};

var topsportLayerConfig = defaultTopsportLayerConfig;

export function getTopsportLayerConfig() {
  return topsportLayerConfig;
};

export function setTopsportLayerConfig(newConfig=false) {
  if (newConfig === false) {
    topsportLayerConfig = defaultTopsportLayerConfig;
  } else {
    topsportLayerConfig = newConfig;
  }
}

export default function TopSportCountLayer() {
    const dispatch = useDispatch();
    const { topsportLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, topsportLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        (object) => {
            if (topsportLayer) {
                var currColor = [0, 0, 0];
                for (var i = 0; i < topsportLayerConfig.legend.labels.length; i++) {
                    var currLabel = topsportLayerConfig.legend.labels[i];
                    if (currLabel === object.properties.topsport1) {
                        currColor = topsportLayerConfig.legend.colors[i];
                    }
                }
                return currColor;
            }
        }, [topsportLayer]
    );

    if (topsportLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: TOPSPORT_LAYER_ID,
            filled: topsportLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : topsportLayerConfig.opacity,
            pointRadiusMinPixels: topsportLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            getLineColor: topsportLayerConfig.linecolor,
            lineWidthMinPixels: topsportLayerConfig.linewidth,
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({
                        id: TOPSPORT_LAYER_ID,
                        layerAttributes: { ...topsportLayerConfig },
                    })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            updateTriggers: {
                getFillColor: topsportLayerConfig,
                getFillColor: topsportLayerConfig.legend,
                getLineColor: topsportLayerConfig,
                opacity: topsportLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: "<strong>Postcode</strong>: " + info.object.properties.postcode
                            + "<br/><strong>Totalsport Count</strong>: " + parseFloat(info.object.properties.totalsport).toFixed(0)
                            + "<br/><strong>Mainsport Count</strong>: " + parseFloat(info.object.properties.mainsport).toFixed(0)
                            + "<br/><strong>Compsport Count</strong>: " + parseFloat(info.object.properties.compsport).toFixed(0)
                            + `<br/><strong>(1)</strong>: ${info.object.properties.topsport1} (${info.object.properties.topsport1count})`
                            + `<br/><strong>(2)</strong>: ${info.object.properties.topsport2} (${info.object.properties.topsport2count})`
                            + `<br/><strong>(3)</strong>: ${info.object.properties.topsport3} (${info.object.properties.topsport3count})`
                            + `<br/><strong>(4)</strong>: ${info.object.properties.topsport4} (${info.object.properties.topsport4count})`
                            + `<br/><strong>(5)</strong>: ${info.object.properties.topsport5} (${info.object.properties.topsport5count})`
                            + `<br/><strong>(6)</strong>: ${info.object.properties.topsport6} (${info.object.properties.topsport6count})`
                            + `<br/><strong>(7)</strong>: ${info.object.properties.topsport7} (${info.object.properties.topsport7count})`
                            + `<br/><strong>(8)</strong>: ${info.object.properties.topsport8} (${info.object.properties.topsport8count})`
                            + `<br/><strong>(9)</strong>: ${info.object.properties.topsport9} (${info.object.properties.topsport9count})`
                            + `<br/><strong>(10)</strong>: ${info.object.properties.topsport10} (${info.object.properties.topsport10count})`
                            + `<br/><strong>(11)</strong>: ${info.object.properties.topsport11} (${info.object.properties.topsport11count})`
                            + `<br/><strong>(12)</strong>: ${info.object.properties.topsport12} (${info.object.properties.topsport12count})`
                            + `<br/><strong>(13)</strong>: ${info.object.properties.topsport13} (${info.object.properties.topsport13count})`
                            + `<br/><strong>(14)</strong>: ${info.object.properties.topsport14} (${info.object.properties.topsport14count})`
                            + `<br/><strong>(15)</strong>: ${info.object.properties.topsport15} (${info.object.properties.topsport15count})`
                        ,
                      style: {},
                    };
                }
            },
        });
    }
}
