import { executeSQL } from '@carto/react-api';
import { city, country, vision } from 'utils/urlParamUtil';
import POIMapping from 'assets/config/poi.json';
import { setFoundSource } from 'store/layerSlice';
import { FRA_GIRONDE_SPH3_LAYER_ID, getIPLayerConfig, setIPLayerConfig } from 'components/layers/FraGirondeSph3Layer';
import { updateLayer } from '@carto/react-redux/';
import { FRA_GIRONDE_SP_POPULATION_LAYER_ID, getPopulationH3LayerConfig, setPopulationH3LayerConfig } from 'components/layers/FraGirondeSpPopulationLayer';
import { FRA_GIRONDE_SP_PURCHASING_LAYER_ID, getPurchasingH3LayerConfig, setPurchasingH3LayerConfig } from 'components/layers/FraGirondeSpPurchasingLayer';
import { POI_MALLS_LAYER_ID, getPoiMallsLayerConfig, setPoiMallsLayerConfig } from 'components/layers/POI/PoiMallsLayer';
import { POI_SPORTSCLUBS_LAYER_ID, getPoiSportsclubsLayerConfig, setPoiSportsclubsLayerConfig } from 'components/layers/POI/PoiSportsclubsLayer';
import { POI_SPORTSCOMPLEX_LAYER_ID, getPoiSportscomplexLayerConfig, setPoiSportscomplexLayerConfig } from 'components/layers/POI/PoiSportscomplexLayer';
import { POI_PARKS_LAYER_ID, getPoiParksLayerConfig, setPoiParksLayerConfig } from 'components/layers/POI/PoiParksLayer';
import { POI_STADIUM_LAYER_ID, getPoiStadiumLayerConfig, setPoiStadiumLayerConfig } from 'components/layers/POI/PoiStadiumLayer';
import { POI_SCHOOL_LAYER_ID, getPoiSchoolLayerConfig, setPoiSchoolLayerConfig } from 'components/layers/POI/PoiSchoolLayer';
import { POI_UNIVERSITY_LAYER_ID, getPoiUniversityLayerConfig, setPoiUniversityLayerConfig } from 'components/layers/POI/PoiUniversityLayer';
import { POI_COMPETITORS_LAYER_ID, getPoiCompetitorsLayerConfig, setPoiCompetitorsLayerConfig } from 'components/layers/POI/PoiCompetitorsLayer';
import { POI_SHOPLOTS_LAYER_ID, getPoiShoplotsLayerConfig, setPoiShoplotsLayerConfig } from 'components/layers/POI/PoiShoplotsLayer';
import { POI_HIGHSTREETRETAIL_LAYER_ID, getPoiHighStreetretailLayerConfig, setPoiHighStreetretailLayerConfig } from 'components/layers/POI/PoiHighStreetretailLayer';
import { POI_COMMERCIALBUILDING_LAYER_ID, getPoiCommercialbuildingLayerConfig, setPoiCommercialbuildingLayerConfig } from 'components/layers/POI/PoiCommercialbuildingLayer';
import { POI_SUPERMARKET_LAYER_ID, getPoiSupermarketLayerConfig, setPoiSupermarketLayerConfig } from 'components/layers/POI/PoiSupermarketLayer';
import { POI_CBD_LAYER_ID, getPoiCBDLayerConfig, setPoiCBDLayerConfig } from 'components/layers/POI/PoiCBDLayer';
import { POI_LOGISTICAREA_LAYER_ID, getPoiLogisticareaLayerConfig, setPoiLogisticareaLayerConfig } from 'components/layers/POI/PoiLogisticareaLayer';
import { POI_METROLINES_LAYER_ID, getPoiMetrolinesLayerConfig, setPoiMetrolinesLayerConfig } from 'components/layers/POI/PoiMetrolinesLayer';
import { POI_HIGHWAYS_LAYER_ID, getPoiHighwaysLayerConfig, setPoiHighwaysLayerConfig } from 'components/layers/POI/PoiHighwaysLayer';
import { POI_MANMADEBARRIERS_LAYER_ID, getPoiManMadebarriersLayerConfig, setPoiManMadebarriersLayerConfig } from 'components/layers/POI/PoiManMadebarriersLayer';
import { POI_NATURALBARRIER_LAYER_ID, getPoiNaturalbarrierLayerConfig, setPoiNaturalbarrierLayerConfig } from 'components/layers/POI/PoiNaturalbarrierLayer';
import { POI_AIRPORTS_LAYER_ID, getPoiAirportsLayerConfig, setPoiAirportsLayerConfig } from 'components/layers/POI/PoiAirportsLayer';
import { POI_PORT_LAYER_ID, getPoiPortLayerConfig, setPoiPortLayerConfig } from 'components/layers/POI/PoiPortLayer';
import { POI_TRAINSTATION_LAYER_ID, getPoiTrainstationLayerConfig, setPoiTrainstationLayerConfig } from 'components/layers/POI/PoiTrainstationLayer';
import { POI_MAJORBUSHUB_LAYER_ID, getPoiMajorbushubLayerConfig, setPoiMajorbushubLayerConfig } from 'components/layers/POI/PoiMajorbushubLayer';
import { POI_TOURISTAREA_LAYER_ID, getPoiTouristareaLayerConfig, setPoiTouristareaLayerConfig } from 'components/layers/POI/PoiTouristareaLayer';
import { POI_FUTUREGROWTHAREA_LAYER_ID, getPoiFuturegrowthareaLayerConfig, setPoiFuturegrowthareaLayerConfig } from 'components/layers/POI/PoiFuturegrowthareaLayer';
import { POI_INDUSTRIALAREA_LAYER_ID, getPoiIndustrialareaLayerConfig, setPoiIndustrialareaLayerConfig } from 'components/layers/POI/PoiIndustrialareaLayer';
import { POI_OTHERPOI_LAYER_ID, getPoiOtherPoiLayerConfig, setPoiOtherPoiLayerConfig } from 'components/layers/POI/PoiOtherPoiLayer';
import { POI_ADMINBOUNDARIES_LAYER_ID, getPoiAdminBoundariesLayerConfig, setPoiAdminBoundariesLayerConfig } from 'components/layers/POI/PoiAdminBoundariesLayer';
import { DEMOGRAPHY_LAYER_ID, getDemographyLayerConfig, setDemographyLayerConfig } from 'components/layers/DemographyLayer';
import { PURCHASING_POWER_LAYER_ID, getPurchasingPowerLayerConfig, setPurchasingPowerLayerConfig } from 'components/layers/PurchasingPowerLayer';
import { ONLINE_TURNOVER_LAYER_ID, getOnlineTurnoverLayerConfig, setOnlineTurnoverLayerConfig } from 'components/layers/OnlineTurnoverLayer';
import { PHYSICAL_TURNOVER_LAYER_ID, getPhysicalTurnoverLayerConfig, setPhysicalTurnoverLayerConfig } from 'components/layers/PhysicalTurnoverLayer';
import { SEGMENT_LAYER_ID, getSegmentLayerConfig, setSegmentLayerConfig } from 'components/layers/SegmentLayer';
import { RWI_LAYER_ID, getRwiLayerConfig, setRwiLayerConfig } from 'components/layers/RwiLayer';
import { CITY_BOUNDARY_LAYER_ID, getCityBoundaryLayerConfig, setCityBoundaryLayerConfig } from 'components/layers/CityBoundaryLayer';
import { OPPORTUNITY_AREA_LAYER_ID, getOpportunityAreaLayerConfig, setOpportunityAreaLayerConfig } from 'components/layers/OpportunityAreaLayer';
import { LIVING_AREA_LAYER_ID, getLivingAreaLayerConfig, setLivingAreaLayerConfig } from 'components/layers/LivingAreaLayer';
import { STORE_LOCATION_LAYER_ID, getStoreLocationLayerConfig, setStoreLocationLayerConfig } from 'components/layers/StoreLocationLayer';
import { FRA_GIRONDE_TIMESERIES_LAYER_ID, getTsLayerConfig, setTsLayerConfig } from 'components/layers/FraGirondeTimeseriesLayer';
import { getToEstimationLayerConfig, setToEstimationLayerConfig, TO_ESTIMATION_LAYER_ID } from 'components/layers/TOEstimationLayer';
import toEstimationSource from 'data/sources/toestimationSource';
import { getTopsportLayerConfig, setTopsportLayerConfig, TOPSPORT_LAYER_ID } from 'components/layers/TopSportCountLayer';
import { CUSTOMERPERFORMANCE_LAYER_ID, getCustomerperformanceLayerConfig, setCustomerperformanceLayerConfig } from 'components/layers/CustomerPerformanceLayer';

export async function findAvailableLayerSource(dispatch, credentials) {
    var layersToFind = [
        {"layername": "IP", "tablename": `SUMMARY_IP_${country}_${city}`},
        {"layername": "Demography", "tablename": `demographygeom_${vision}_${country}_${city}`},
        {"layername": "PurchasingPower", "tablename": `purchasingpowergeom_${vision}_${country}_${city}`},
        {"layername": "Turnover", "tablename": `turnover_${vision}_${country}_${city}`},
        {"layername": "RWI", "tablename": `RWI_${country}_${city}`},
        {"layername": "VOC", "tablename": `segments_${vision}_${country}_${city}`},
        {"layername": "TopSport", "tablename": `TOP15_${vision}_${country}_${city}`},
        {"layername": "CustomerPerformance", "tablename": `CUSTOMER_PERFORMANCE_${country}_${city}`},
    ];
    var poiArray = POIMapping.pois;
    for (var i = 0; i < poiArray.length; i++) {
        var currPoi = poiArray[i];
        var formattedCurrPoiName = currPoi.name.replaceAll(" ", "");
        layersToFind.push({"layername": `POI_${formattedCurrPoiName}`, "tablename": `POI_${formattedCurrPoiName}_${vision}_${country}_${city}`})
    }

    var layerSourceFound = [];
    var tableliststring = ``;
    for (var i = 0; i < layersToFind.length; i++) {
        var currTable = layersToFind[i];
        if (tableliststring.length > 0) {
            tableliststring = tableliststring + ", "
        }
        tableliststring = `${tableliststring}"${currTable.tablename}"`;
    }
    var checkExistsQuery = `
        SELECT DISTINCT 
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN (${tableliststring});
    `;
    var tableFoundResult = await executeSQL({ 
        credentials, 
        query: checkExistsQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    for (var i = 0; i < layersToFind.length; i++) {
        for (var j = 0; j < tableFoundResult.length; j++) {
            if (tableFoundResult[j].table_name === layersToFind[i].tablename) {
                layerSourceFound.push(layersToFind[i].layername);
            }
        }
    }
    var checkTOEstimationLayerQuery = `
        SELECT
            COUNT(1) AS count
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\` 
        WHERE 
            UPPER(country) = UPPER("${country}") 
            AND UPPER(city) = UPPER("${city}")
    `;
    var toEstimationResult = await executeSQL({ 
        credentials, 
        query: checkTOEstimationLayerQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    if (toEstimationResult.length > 0 && toEstimationResult[0].count > 0) {
        layerSourceFound.push("TOEstimation");
    }
    dispatch(setFoundSource(layerSourceFound));
    return 0;
}

export async function fetchIPDates(credentials) {
    var dateQuery = `
        SELECT
            MAX(date) AS maxDate,
            MIN(date) AS minDate
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
    `;
    var dateResult = await executeSQL({ credentials, query: dateQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    return dateResult;
}

export async function configureIPLayersConfig(dispatch, credentials) {
    var totalRangeQuery = `
        SELECT
            MAX(visits*100) AS maxVal, 
            MIN(visits*100) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
    `;
    var rangeResult = await executeSQL({ credentials, query: totalRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getIPLayerConfig();
        var maxVal = rangeResult[0].maxVal;
        var minVal = rangeResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setIPLayerConfig(config);
        dispatch( updateLayer(config) );
    }

    var totalPopulationQuery = `
        SELECT
            MAX(total_population_sum) AS maxVal, 
            MIN(total_population_sum) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
    `;
    var totalPopResult = await executeSQL({ credentials, query: totalPopulationQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (totalPopResult.length > 0) {
        var config = getPopulationH3LayerConfig();
        var maxVal = totalPopResult[0].maxVal;
        var minVal = totalPopResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setPopulationH3LayerConfig(config);
        dispatch( updateLayer(config) );
    }

    var totalPurchasingQuery = `
        SELECT
            MAX(purchasingpowerpercapita_avg) AS maxVal, 
            MIN(purchasingpowerpercapita_avg) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
    `;
    var totalPurResult = await executeSQL({ credentials, query: totalPurchasingQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (totalPurResult.length > 0) {
        var config = getPurchasingH3LayerConfig();
        var maxVal = totalPurResult[0].maxVal;
        var minVal = totalPurResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setPurchasingH3LayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export async function configureDemographyLayerConfig(dispatch, credentials) {
    var populationRangeQuery = `
        SELECT
            MAX(total_population) AS maxVal, 
            MIN(total_population) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demographygeom_${vision}_${country}_${city}\`
    `;
    var rangeResult = await executeSQL({ credentials, query: populationRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getDemographyLayerConfig();
        var maxVal = rangeResult[0].maxVal;
        var minVal = rangeResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setDemographyLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export async function configurePurchasingPowerLayerConfig(dispatch, credentials) {
    var purchasingRangeQuery = `
        SELECT
            MAX(purchasingpowerpercapita) AS maxVal, 
            MIN(purchasingpowerpercapita) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.purchasingpowergeom_${vision}_${country}_${city}\`
    `;
    var rangeResult = await executeSQL({ credentials, query: purchasingRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getPurchasingPowerLayerConfig();
        var maxVal = rangeResult[0].maxVal;
        var minVal = rangeResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setPurchasingPowerLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export async function configureTurnoverLayersConfig(dispatch, credentials) {
    var turnoverRangeQuery = `
        SELECT
            MAX(online_to) AS maxOnVal, 
            MIN(online_to) AS minOnVal,
            MAX(physical_to) AS maxOffVal,
            MIN(physical_to) AS minOffVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.turnover_${vision}_${country}_${city}\`
    `;
    var rangeResult = await executeSQL({ credentials, query: turnoverRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getOnlineTurnoverLayerConfig();
        var maxVal = rangeResult[0].maxOnVal;
        var minVal = rangeResult[0].minOnVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setOnlineTurnoverLayerConfig(config);
        config = getPhysicalTurnoverLayerConfig();
        maxVal = rangeResult[0].maxOffVal;
        minVal = rangeResult[0].minOffVal;
        numTicks = config.legend.colors.length-1;
        eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setPhysicalTurnoverLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export async function configureTopsportLayerConfig(dispatch, credentials) {
    var topSportsQuery = `
        SELECT DISTINCT
            sports
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.TOP15_${vision}_${country}_${city}\`
    `;
    var topSportsResult = await executeSQL({ credentials, query: topSportsQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (topSportsResult.length > 0) {
        var config = getTopsportLayerConfig();
        var sportsLabel = [];
        for (var i = 0; i < topSportsResult.length; i++) {
            sportsLabel.push(topSportsResult[i].sports);
        }
        var newLegend = {...config.legend};
        newLegend.labels = sportsLabel;
        config.legend = newLegend;
        setTopsportLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}


export async function configureCustomerPerformanceLayerConfig(dispatch, credentials, excluded) {
    console.log(excluded);
    console.log(excluded.join(","));
    var topSportsQuery = `
        SELECT
            sport, SUM(\`Total Customers Physical\` + \`Total Customers Physical\`) AS total_customers
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.CUSTOMER_PERFORMANCE_${country}_${city}\`
        WHERE sport NOT IN (${excluded.join(",")})
        GROUP BY sport
        ORDER BY total_customers DESC
    `;
    var topSportsResult = await executeSQL({ credentials, query: topSportsQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (topSportsResult.length > 0) {
        var config = getCustomerperformanceLayerConfig();
        var sportsLabel = [];
        for (var i = 0; i < topSportsResult.length; i++) {
            sportsLabel.push(topSportsResult[i].sport);
        }
        var newLegend = {...config.legend};
        newLegend.labels = sportsLabel;
        config.legend = newLegend;
        setCustomerperformanceLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}


export async function configureTOEstimationLayerConfig(dispatch, credentials, params=false) {
    var queryParams;
    if (params) {
        queryParams = params;
    } else {
        queryParams = {...toEstimationSource.queryParameters};
    }
    console.log(queryParams);
    var estimationRangeQuery = `
        SELECT
            MAX(predicted_annual_turnover) AS maxVal, 
            MIN(predicted_annual_turnover) AS minVal
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\`
        WHERE
            modelname = "${queryParams.modelName}"
            AND UPPER(city) = UPPER("${city}")
            AND UPPER(country) = UPPER("${country}")
            AND sales_area = ${queryParams.salesArea}
            AND store_format = "${queryParams.storeFormat}"
            AND store_age = 2
    `;
    var rangeResult = await executeSQL({ credentials, query: estimationRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getToEstimationLayerConfig();
        var maxVal = rangeResult[0].maxVal;
        var minVal = rangeResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setToEstimationLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export function getLayerConfig(layerid) {
    if (layerid === FRA_GIRONDE_SPH3_LAYER_ID) {
        return [getIPLayerConfig, setIPLayerConfig];
    } else if (layerid === FRA_GIRONDE_SP_POPULATION_LAYER_ID) {
        return [getPopulationH3LayerConfig, setPopulationH3LayerConfig];
    } else if (layerid === FRA_GIRONDE_SP_PURCHASING_LAYER_ID) {
        return [getPurchasingH3LayerConfig, setPurchasingH3LayerConfig];
    } else if (layerid === POI_MALLS_LAYER_ID) {
        return [getPoiMallsLayerConfig, setPoiMallsLayerConfig];
    } else if (layerid === POI_SPORTSCLUBS_LAYER_ID) {
        return [getPoiSportsclubsLayerConfig, setPoiSportsclubsLayerConfig];
    } else if (layerid === POI_SPORTSCOMPLEX_LAYER_ID) {
        return [getPoiSportscomplexLayerConfig, setPoiSportscomplexLayerConfig];
    } else if (layerid === POI_PARKS_LAYER_ID) {
        return [getPoiParksLayerConfig, setPoiParksLayerConfig];
    } else if (layerid === POI_STADIUM_LAYER_ID) {
        return [getPoiStadiumLayerConfig, setPoiStadiumLayerConfig];
    } else if (layerid === POI_SCHOOL_LAYER_ID) {
        return [getPoiSchoolLayerConfig, setPoiSchoolLayerConfig];
    } else if (layerid === POI_UNIVERSITY_LAYER_ID) {
        return [getPoiUniversityLayerConfig, setPoiUniversityLayerConfig];
    } else if (layerid === POI_COMPETITORS_LAYER_ID) {
        return [getPoiCompetitorsLayerConfig, setPoiCompetitorsLayerConfig];
    } else if (layerid === POI_SHOPLOTS_LAYER_ID) {
        return [getPoiShoplotsLayerConfig, setPoiShoplotsLayerConfig];
    } else if (layerid === POI_HIGHSTREETRETAIL_LAYER_ID) {
        return [getPoiHighStreetretailLayerConfig, setPoiHighStreetretailLayerConfig];
    } else if (layerid === POI_COMMERCIALBUILDING_LAYER_ID) {
        return [getPoiCommercialbuildingLayerConfig, setPoiCommercialbuildingLayerConfig];
    } else if (layerid === POI_SUPERMARKET_LAYER_ID) {
        return [getPoiSupermarketLayerConfig, setPoiSupermarketLayerConfig];
    } else if (layerid === POI_CBD_LAYER_ID) {
        return [getPoiCBDLayerConfig, setPoiCBDLayerConfig];
    } else if (layerid === POI_LOGISTICAREA_LAYER_ID) {
        return [getPoiLogisticareaLayerConfig, setPoiLogisticareaLayerConfig];
    } else if (layerid === POI_METROLINES_LAYER_ID) {
        return [getPoiMetrolinesLayerConfig, setPoiMetrolinesLayerConfig];
    } else if (layerid === POI_HIGHWAYS_LAYER_ID) {
        return [getPoiHighwaysLayerConfig, setPoiHighwaysLayerConfig];
    } else if (layerid === POI_MANMADEBARRIERS_LAYER_ID) {
        return [getPoiManMadebarriersLayerConfig, setPoiManMadebarriersLayerConfig];
    } else if (layerid === POI_NATURALBARRIER_LAYER_ID) {
        return [getPoiNaturalbarrierLayerConfig, setPoiNaturalbarrierLayerConfig];
    } else if (layerid === POI_AIRPORTS_LAYER_ID) {
        return [getPoiAirportsLayerConfig, setPoiAirportsLayerConfig];
    } else if (layerid === POI_PORT_LAYER_ID) {
        return [getPoiPortLayerConfig, setPoiPortLayerConfig];
    } else if (layerid === POI_TRAINSTATION_LAYER_ID) {
        return [getPoiTrainstationLayerConfig, setPoiTrainstationLayerConfig];
    } else if (layerid === POI_MAJORBUSHUB_LAYER_ID) {
        return [getPoiMajorbushubLayerConfig, setPoiMajorbushubLayerConfig];
    } else if (layerid === POI_TOURISTAREA_LAYER_ID) {
        return [getPoiTouristareaLayerConfig, setPoiTouristareaLayerConfig];
    } else if (layerid === POI_FUTUREGROWTHAREA_LAYER_ID) {
        return [getPoiFuturegrowthareaLayerConfig, setPoiFuturegrowthareaLayerConfig];
    } else if (layerid === POI_INDUSTRIALAREA_LAYER_ID) {
        return [getPoiIndustrialareaLayerConfig, setPoiIndustrialareaLayerConfig];
    } else if (layerid === POI_OTHERPOI_LAYER_ID) {
        return [getPoiOtherPoiLayerConfig, setPoiOtherPoiLayerConfig];
    } else if (layerid === POI_ADMINBOUNDARIES_LAYER_ID) {
        return [getPoiAdminBoundariesLayerConfig, setPoiAdminBoundariesLayerConfig];
    } else if (layerid === DEMOGRAPHY_LAYER_ID) {
        return [getDemographyLayerConfig, setDemographyLayerConfig];
    } else if (layerid === PURCHASING_POWER_LAYER_ID) {
        return [getPurchasingPowerLayerConfig, setPurchasingPowerLayerConfig];
    } else if (layerid === ONLINE_TURNOVER_LAYER_ID ){
        return [getOnlineTurnoverLayerConfig, setOnlineTurnoverLayerConfig];
    } else if (layerid === PHYSICAL_TURNOVER_LAYER_ID) {
        return [getPhysicalTurnoverLayerConfig, setPhysicalTurnoverLayerConfig];
    } else if (layerid === SEGMENT_LAYER_ID) {
        return [getSegmentLayerConfig, setSegmentLayerConfig];
    } else if (layerid === RWI_LAYER_ID) {
        return [getRwiLayerConfig, setRwiLayerConfig];
    } else if (layerid === CITY_BOUNDARY_LAYER_ID) {
        return [getCityBoundaryLayerConfig, setCityBoundaryLayerConfig];
    } else if (layerid === OPPORTUNITY_AREA_LAYER_ID) {
        return [getOpportunityAreaLayerConfig, setOpportunityAreaLayerConfig];
    } else if (layerid === LIVING_AREA_LAYER_ID) {
        return [getLivingAreaLayerConfig, setLivingAreaLayerConfig];
    } else if (layerid === STORE_LOCATION_LAYER_ID) {
        return [getStoreLocationLayerConfig, setStoreLocationLayerConfig];
    } else if (layerid === FRA_GIRONDE_TIMESERIES_LAYER_ID) {
        return [getTsLayerConfig, setTsLayerConfig];
    } else if (layerid === TO_ESTIMATION_LAYER_ID) {
        return [getToEstimationLayerConfig, setToEstimationLayerConfig];
    } else if (layerid === TOPSPORT_LAYER_ID) {
        return [getTopsportLayerConfig, setTopsportLayerConfig];
    } else if (layerid === CUSTOMERPERFORMANCE_LAYER_ID) {
        return [getCustomerperformanceLayerConfig, setCustomerperformanceLayerConfig];
    }
}

export async function retrieveSavedCartoImages(dispatch, credentials) {
    var getImagesQuery = `
        SELECT DISTINCT
            imagetype
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_carto_images\`
        WHERE
            visionid = "${vision}"
    `;
    var imagesResult = await executeSQL({ credentials, query: getImagesQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var retrievedImageType = [];
    for (var i = 0; i < imagesResult.length; i++) {
        if (imagesResult[i].imagetype === 1) {
            retrievedImageType.push("Opportunity Area")
        } else if (imagesResult[i].imagetype === 3) {
            retrievedImageType.push("Living Area")
        } else if (imagesResult[i].imagetype === 4) {
            retrievedImageType.push("City Boundary")
        } else if (imagesResult[i].imagetype === 5) {
            retrievedImageType.push("Development Plan (Store location)")
        } else if (imagesResult[i].imagetype === 6) {
            retrievedImageType.push("Customer Segmentation")
        }
    }
    return retrievedImageType;
}

export async function getPois(dispatch, credentials) {
    var poiArray = POIMapping.pois;
    var foundPoisPromises = [];
    for (var i = 0; i < poiArray.length; i++) {
        var currPoi = poiArray[i];
        var checkPoiTableQuery = `
            SELECT DISTINCT
                table_name
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
            WHERE
                table_name = "${currPoi.tableprefix}_${vision}_${country}_${city}"
        `;
        var poiPromises = executeSQL({ credentials, query: checkPoiTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        foundPoisPromises.push(poiPromises);
    }
    return foundPoisPromises;
}

export async function getPoiDetails(dispatch, credentials) {
    var poiArray = POIMapping.pois;
    var poiDetailsPromises = [];
    var poiPromiseList = await getPois(dispatch, credentials);
    await Promise.all(poiPromiseList).then((foundPoiList) => {
        for (var i = 0; i < foundPoiList.length; i++) {
            if (foundPoiList[i].length > 0) {
                var getPoiCountTableQuery = `
                    SELECT
                        COUNT(1) AS value,
                        "${poiArray[i].name}" AS name
                    FROM 
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${foundPoiList[i][0].table_name}\`
                `;
                var promise = executeSQL({ credentials, query: getPoiCountTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
                poiDetailsPromises.push(promise);
            }
        }
    });
    return poiDetailsPromises;
}

export async function getTopSportsDetail(dispatch, credentials) {
    var topSportQuery = `
        SELECT
            SUM(mainsport + compsport) AS totalsport,
            sports
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.TOP15_${vision}_${country}_${city}\`
        GROUP BY sports
    `;
    var topSportsResult = await executeSQL({ credentials, query: topSportQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    return topSportsResult;
}

export async function getCustomerPerformanceDetail(dispatch, credentials) {
    var topSportQuery = `
        SELECT
            SUM(\`Total Customers Physical\` + \`Total Customers Digital\`) AS totalcustomer,
            sport
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.CUSTOMER_PERFORMANCE_${country}_${city}\`
        GROUP BY sport
    `;
    var topSportsResult = await executeSQL({ credentials, query: topSportQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    return topSportsResult;
}