import { setCityBoundary, setDrawingGeometry, setLivingArea, setOpportunityArea, setStoreLocation } from "store/polygonSlice";
import { city, country, vision } from "./urlParamUtil";
import { executeSQL } from '@carto/react-api';
import { getLivingAreaLayerConfig, setLivingAreaLayerConfig } from "components/layers/LivingAreaLayer";
import { updateLayer } from "@carto/react-redux";
import { getStoreLocationLayerConfig, setStoreLocationLayerConfig } from "components/layers/StoreLocationLayer";
import { getTsLayerConfig, setTsLayerConfig } from "components/layers/FraGirondeTimeseriesLayer";

export async function createQueryJob(query, credentials) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${credentials.accessToken}`);
    var raw = JSON.stringify({
        "query": query,
        "queryParameters": {}
    });
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    try {
        var response = await fetch(`https://carto.ure.decathlon.net/api/v3/sql/${process.env.REACT_APP_CONNECTION_NAME}/job`, requestOptions);
        if (!response.ok) {
            console.log(response);
            return -1;
        }
        const data = await response.json();
        var resultHeaders = new Headers();
        resultHeaders.append("Authorization", `Bearer ${credentials.accessToken}`);
        var resultOptions = {
            method: 'GET',
            headers: resultHeaders,
            redirect: 'follow'
        };
        var status = "running";
        while (status === "running") {
            var result = await fetch(`https://carto.ure.decathlon.net/api/v3/sql/${process.env.REACT_APP_CONNECTION_NAME}/job/${data.externalId}`, resultOptions);
            if (!result.ok) {
                console.log(result);
                return -1;
            }
            const resultdata = await result.json();
            status = resultdata.status;
            if (status === "running") {
                await new Promise(resolve => setTimeout(resolve, 10000));
            }
        }
    } catch (error) {
        console.log(error);
        return -1;
    }
    return 0;
}

export async function copyRegionBoundaryToCityBoundary(_, credentials) {
    var copyBoundaryQuery = `
        INSERT INTO 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
            (geometry, id, visionid, cityname, description, createdat, updatedat)
        SELECT
            geometry,
            GENERATE_UUID() AS id,
            "${vision}" AS visionid,
            "Default City Boundary" AS cityname,
            "" AS description,
            CURRENT_TIMESTAMP() AS createdat, 
            CURRENT_TIMESTAMP() AS updatedat
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_regionboundary\` 
        WHERE 
            cityname = "${city}" 
            AND countryname = "${country}"
    `;
    await executeSQL({ credentials, query: copyBoundaryQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
}

export async function retrieveCityBoundary(dispatch, credentials) {
    var getCityBoundaryQuery = `
        SELECT
            *,
            ST_ASGEOJSON(geometry) AS geojson
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
        WHERE
            visionid = "${vision}"
    `;
    var cityBoundaryResult = await executeSQL({ credentials, query: getCityBoundaryQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (cityBoundaryResult.length === 0) {
        await copyRegionBoundaryToCityBoundary(dispatch, credentials);
        cityBoundaryResult = await executeSQL({ credentials, query: getCityBoundaryQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    }
    var retrievedBoundary = [];
    for (var i = 0; i < cityBoundaryResult.length; i++) {
        var area = {};
        let { geojson, ...properties } = cityBoundaryResult[i];
        properties.name = properties.cityname;
        properties.enriched = true;
        var area = {
            'type': 'Feature',
            'properties': properties,
            'geometry': JSON.parse(geojson),
        }
        retrievedBoundary.push(area);
    }
    dispatch(setCityBoundary(retrievedBoundary));
}

export async function retrieveOpportunityArea(dispatch, credentials) {
    var getOppAreaQuery = `
        SELECT
            *,
            ST_ASGEOJSON(geometry) AS geojson
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_opportunityarea\`
        WHERE
            visionid = "${vision}"
        ORDER BY oppareaname;
    `;
    var oppAreaResult = await executeSQL({ credentials, query: getOppAreaQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var retrievedAreas = [];
    for (var i = 0; i < oppAreaResult.length; i++) {
        var area = {};
        let { geojson, ...properties } = oppAreaResult[i];
        properties.name = properties.oppareaname;
        properties.enriched = true;
        var area = {
            'type': 'Feature',
            'properties': properties,
            'geometry': JSON.parse(geojson),
        }
        retrievedAreas.push(area);
    }
    dispatch(setOpportunityArea(retrievedAreas));
}

export async function retrieveLivingArea(dispatch, credentials) {
    var getLivAreaQuery = `
        SELECT
            a.*,
            ST_ASGEOJSON(a.geometry) AS geojson,
            b.rank,
            b.score
        FROM
            (
                SELECT
                    *
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\`
                WHERE
                    visionid = "${vision}"
            ) a
        LEFT JOIN
            (
                SELECT
                    *,
                    ROW_NUMBER() OVER(PARTITION BY livingareaname ORDER BY updated_datetime desc) AS rn
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarearanking\`
                WHERE
                    CAST(visionid AS STRING) = "${vision}"
            ) b
        ON
            a.livingareaname = b.livingareaname
            AND rn = 1
        ORDER BY a.livingareaname;
    `;
    var livAreaResult = await executeSQL({ credentials, query: getLivAreaQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var retrievedAreas = [];
    for (var i = 0; i < livAreaResult.length; i++) {
        var area = {};
        let { geojson, ...properties } = livAreaResult[i];
        properties.name = properties.livingareaname;
        properties.enriched = true;
        var area = {
            'type': 'Feature',
            'properties': properties,
            'geometry': JSON.parse(geojson),
        }
        retrievedAreas.push(area);
    }
    dispatch(setLivingArea(retrievedAreas));
    //dispatch(setDrawingGeometry(retrievedAreas));
    var maxScore = 0;
    var minScore = 100;
    for (var i = 0; i < livAreaResult.length; i++) {
        if (livAreaResult[i].score > maxScore) {
            maxScore = livAreaResult[i].score;
        }
        if (livAreaResult[i].score < minScore) {
            minScore = livAreaResult[i].score;
        }
    }
    var config = getLivingAreaLayerConfig();
    var numTicks = config.legend.colors.length-1;
    var eachTick = Math.round((maxScore - minScore) * 100 / numTicks)/100;
    if ((maxScore - minScore) / numTicks > 1) {
        eachTick = Math.round(eachTick);
    }
    var updatedLabel = [];
    for (let i = 0; i < numTicks; i++) {
        var currTick = minScore + i * eachTick;
        if (currTick > 0) {
            currTick = Math.round(currTick * 10) / 10;
        }
        updatedLabel.push(currTick);
    }
    var newLegend = {...config.legend};
    newLegend.labels = updatedLabel;
    config.legend = newLegend;
    setLivingAreaLayerConfig(config);
    dispatch( updateLayer(config) );
}

export async function configureLivingAreaScore(dispatch, credentials) {
    var scoreRangeQuery = `
        SELECT
            MAX(score) AS maxVal, 
            MIN(score) AS minVal
        FROM
            (
                SELECT
                    *,
                    ROW_NUMBER() OVER(PARTITION BY livingareaname ORDER BY updated_datetime desc) AS rn
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarearanking\`
                WHERE
                    CAST(visionid AS STRING) = "${vision}"
            )
        WHERE
            rn = 1
    `;
    var rangeResult = await executeSQL({ credentials, query: scoreRangeQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    if (rangeResult.length > 0) {
        var config = getLivingAreaLayerConfig();
        var maxVal = rangeResult[0].maxVal;
        var minVal = rangeResult[0].minVal;
        var numTicks = config.legend.colors.length-1;
        var eachTick = Math.round((maxVal - minVal) * 100 / numTicks)/100;
        if ((maxVal - minVal) / numTicks > 1) {
            eachTick = Math.round(eachTick);
        }
        var updatedLabel = [];
        for (let i = 0; i < numTicks; i++) {
            var currTick = minVal + i * eachTick;
            if (currTick > 0) {
                currTick = Math.round(currTick * 10) / 10;
            }
            updatedLabel.push(currTick);
        }
        var newLegend = {...config.legend};
        newLegend.labels = updatedLabel;
        config.legend = newLegend;
        setLivingAreaLayerConfig(config);
        dispatch( updateLayer(config) );
    }
}

export async function retrieveStoreLocations(dispatch, credentials) {
    var getStoresQuery = `
        SELECT
            *
        FROM
            (
                (
                    SELECT
                        id,
                        visionid,
                        storeid,
                        description,
                        storename,
                        store_format,
                        CAST(opening_date AS STRING) AS opening_date,
                        sales_area,
                        estimated_to,
                        geometry,
                        createdat,
                        updatedat,
                        ST_ASGEOJSON(geometry) AS geojson,
                        "new" AS type
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
                    WHERE
                        visionid = "${vision}"
                )
                UNION ALL
                (
                    SELECT
                        site_code AS id,
                        "${vision}" AS visionid,
                        site_code AS storeid,
                        address AS description,
                        site_name AS storename,
                        format AS store_format,
                        CAST(opening_date AS STRING) AS opening_date,
                        NULL AS sales_area,
                        NULL AS estimated_to,
                        geom AS geometry,
                        CAST(NULL AS TIMESTAMP) AS createdat,
                        CAST(NULL AS TIMESTAMP) AS updatedat,
                        ST_ASGEOJSON(geom) AS geojson,
                        "existing" AS type
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existing_store_locations\`
                    WHERE 
                        UPPER(REGEXP_REPLACE(country, ' ', '_')) = "${country}" 
                        AND UPPER(REGEXP_REPLACE(city, ' ', '_')) = "${city}"
                  )
            ) 
        ORDER BY storename
    `;
    var storesResult = await executeSQL({ credentials, query: getStoresQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var retrievedStores = [];
    for (var i = 0; i < storesResult.length; i++) {
        var area = {};
        let { geojson, ...properties } = storesResult[i];
        properties.name = properties.storeid;
        properties.enriched = true;
        var area = {
            'type': 'Feature',
            'properties': properties,
            'geometry': JSON.parse(geojson),
        }
        retrievedStores.push(area);
    }
    dispatch(setStoreLocation(retrievedStores));
    var storeFormats = [];
    for (var i = 0; i < storesResult.length; i++) {
        if (!storeFormats.includes(storesResult[i].store_format)) {
            storeFormats.push(storesResult[i].store_format);
        }
    }
    var currConfig = {...getStoreLocationLayerConfig()};
    setStoreLocationLayerConfig();
    var resetConfig = getStoreLocationLayerConfig();
    var availableLabel = [];
    var availableColor = [];
    for (var i = 0; i < resetConfig.legend.labels.length; i++) {
        for (var j = 0; j < storeFormats.length; j++) {
            if (resetConfig.legend.labels[i] === storeFormats[j] && !availableLabel.includes(storeFormats[j])) {
                availableLabel.push(resetConfig.legend.labels[i]);
                availableColor.push(resetConfig.legend.colors[i]);
            }
        }
    }
    var newLegend = {...currConfig.legend};
    newLegend.labels = availableLabel;
    newLegend.colors = availableColor;
    currConfig.legend = newLegend;
    setStoreLocationLayerConfig(currConfig);
    dispatch( updateLayer(currConfig) );
}

export async function configureStoreLocation(dispatch, credentials) {
    var getAvailableStoreTypesQuery = `
        SELECT DISTINCT
            store_format
        FROM
            (
                SELECT DISTINCT store_format 
                FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
                WHERE visionid = "${vision}"
            )
            UNION ALL
            (
                SELECT DISTINCT format AS store_format
                FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existing_store_locations\`
                WHERE UPPER(REGEXP_REPLACE(country, ' ', '_')) = "${country}" AND UPPER(REGEXP_REPLACE(city, ' ', '_')) = "${city}"
            )
    `;
    var storeFormats = await executeSQL({ credentials, query: getAvailableStoreTypesQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var currConfig = {...getStoreLocationLayerConfig()};
    setStoreLocationLayerConfig();
    var resetConfig = getStoreLocationLayerConfig();
    var availableLabel = [];
    var availableColor = [];
    for (var i = 0; i < resetConfig.legend.labels.length; i++) {
        for (var j = 0; j < storeFormats.length; j++) {
            if (resetConfig.legend.labels[i] === storeFormats[j].store_format && !availableLabel.includes(storeFormats[j].store_format)) {
                availableLabel.push(resetConfig.legend.labels[i]);
                availableColor.push(resetConfig.legend.colors[i]);
            }
        }
    }
    var newLegend = {...currConfig.legend};
    newLegend.labels = availableLabel;
    newLegend.colors = availableColor;
    currConfig.legend = newLegend;
    setStoreLocationLayerConfig(currConfig);
    dispatch( updateLayer(currConfig) );
}

export async function getLivingAreaHourlyTimeSeries(dispatch, credentials) {
    var aggIDCountQuery = `
        CREATE OR REPLACE TABLE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_livingareatimeseriesidcount_${vision}_${country}_${city}\`
        CLUSTER BY cel_h3
        AS (
            SELECT
                cel_h3,
                COUNT(DISTINCT id0) AS id0,
                COUNT(DISTINCT id2) AS id2,
                COUNT(DISTINCT id4) AS id4,
                COUNT(DISTINCT id6) AS id6,
                COUNT(DISTINCT id8) AS id8,
                COUNT(DISTINCT id10) AS id10,
                COUNT(DISTINCT id12) AS id12,
                COUNT(DISTINCT id14) AS id14,
                COUNT(DISTINCT id16) AS id16,
                COUNT(DISTINCT id18) AS id18,
                COUNT(DISTINCT id20) AS id20,
                COUNT(DISTINCT id22) AS id22,
                COUNT(DISTINCT hashed_device_id) AS ui
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\` 
            GROUP BY
                cel_h3
        )
    `;
    var getTimeSeriesQuery = `
        CREATE OR REPLACE TABLE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\`
        CLUSTER BY geom
        AS (
            SELECT
                a.id AS id,
                ST_UNION_AGG(a.geometry) AS geom,
                SUM(id0) AS id0,
                SUM(id2) AS id2,
                SUM(id4) AS id4,
                SUM(id6) AS id6,
                SUM(id8) AS id8,
                SUM(id10) AS id10,
                SUM(id12) AS id12,
                SUM(id14) AS id14,
                SUM(id16) AS id16,
                SUM(id18) AS id18,
                SUM(id20) AS id20,
                SUM(id22) AS id22,
                SUM(ui) AS ui
            FROM
                (SELECT * FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\` WHERE visionid = "${vision}") a
            LEFT JOIN
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.timeseriesidcount_${vision}_${country}_${city}\` b
            ON
                ST_CONTAINS(a.geometry, \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(b.cel_h3))
            GROUP BY
                id
        );
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_livingareatimeseriesidcount_${vision}_${country}_${city}\`;
        SELECT 
            SUM(id0) AS id0,
            SUM(id2) AS id2,
            SUM(id4) AS id4,
            SUM(id6) AS id6,
            SUM(id8) AS id8,
            SUM(id10) AS id10,
            SUM(id12) AS id12,
            SUM(id14) AS id14,
            SUM(id16) AS id16,
            SUM(id18) AS id18,
            SUM(id20) AS id20,
            SUM(id22) AS id22,
            SUM(ui) AS ui
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.timeseriesidcount_${vision}_${country}_${city}\`;
    `;
    await executeSQL({ credentials, query: aggIDCountQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var result = executeSQL({ credentials, query: getTimeSeriesQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    
    return result;
}

export async function prepareHourlyTimeSeries(params, dispatch, credentials) {
    var days = ``;
    for (var i = 0; i < params.day.length; i++) {
        if (days.length > 0) {
            days = days + ", ";
        }
        days = days + `"${params.day[i]}"`;
    }
    var preparequery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\`;
        CREATE TABLE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\`
        CLUSTER BY geom
        AS (
            SELECT
                ST_UNION_AGG(geom) AS geom,
                freguesia,
                SUM(val_all_00 + val_all_01 + val_all_02 + val_all_03 + val_all_04 + val_all_05 + val_all_06 + val_all_07+ val_all_08+val_all_09+val_all_10+val_all_11+val_all_12 + val_all_13 + val_all_14 + val_all_15 + val_all_16 + val_all_17+ val_all_18+val_all_19+val_all_20+val_all_21+val_all_22+val_all_23) AS uniqueids,
                SUM(val_all_00 + val_all_01) AS id0,
                SUM(val_all_02 + val_all_03) AS id2,
                SUM(val_all_04 + val_all_05) AS id4,
                SUM(val_all_06 + val_all_07) AS id6,
                SUM(val_all_08 + val_all_09) AS id8,
                SUM(val_all_10 + val_all_11) AS id10,
                SUM(val_all_12 + val_all_13) AS id12,
                SUM(val_all_14 + val_all_15) AS id14,
                SUM(val_all_16 + val_all_17) AS id16,
                SUM(val_all_18 + val_all_19) AS id18,
                SUM(val_all_20 + val_all_21) AS id20,
                SUM(val_all_22 + val_all_23) AS id22
            FROM 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.mobility_${country}_${city}\`
            WHERE
                FORMAT_DATETIME('%a', datekey) in (${days})
            GROUP BY freguesia
        )
    `;
    await createQueryJob(preparequery, credentials);
    var query = `
        SELECT 
            MAX(id0) AS idhour0,
            MAX(id2) AS idhour2,
            MAX(id4) AS idhour4,
            MAX(id6) AS idhour6,
            MAX(id8) AS idhour8,
            MAX(id10) AS idhour10,
            MAX(id12) AS idhour12,
            MAX(id14) AS idhour14,
            MAX(id16) AS idhour16,
            MAX(id18) AS idhour18,
            MAX(id20) AS idhour20,
            MAX(id22) AS idhour22,
            GREATEST(MAX(id0), MAX(id2), MAX(id4), MAX(id6), MAX(id8), MAX(id10), MAX(id12), MAX(id14), MAX(id16), MAX(id18), MAX(id20), MAX(id22)) AS maxval,
            LEAST(MAX(id0), MAX(id2), MAX(id4), MAX(id6), MAX(id8), MAX(id10), MAX(id12), MAX(id14), MAX(id16), MAX(id18), MAX(id20), MAX(id22)) AS minval
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\` 
    `;
    var result = await executeSQL({ credentials, query: query, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    configureTimeSeriesLayer(0, result[0].maxval, dispatch);
    return result;
}

export async function configureTimeSeriesLayer(minval, maxval, dispatch) {
    var currConfig = {...getTsLayerConfig()};
    var numTicks = currConfig.legend.colors.length-1;
    var eachTick = Math.round((maxval - minval) * 100 / numTicks)/100;
    if ((maxval - minval) / numTicks > 1) {
        eachTick = Math.round(eachTick);
    }
    var updatedLabel = [];
    for (let i = 0; i < numTicks; i++) {
        var currTick = minval + i * eachTick;
        if (currTick > 0) {
            currTick = Math.round(currTick * 10) / 10;
        }
        updatedLabel.push(currTick);
    }
    var newLegend = {...currConfig.legend};
    newLegend.labels = updatedLabel;
    currConfig.legend = newLegend;
    setTsLayerConfig(currConfig);
    dispatch( updateLayer(currConfig) );
}

export async function prepareLivingAreaHourlyTimeSeries(areaids, params, credentials) {
    if (areaids.length < 0) {
        return;
    }
    var areaidstring = ``;
    for (var i = 0; i < areaids.length; i++) {
        if (areaidstring.length > 0) {
            areaidstring = areaidstring + ", ";
        }
        areaidstring = areaidstring + `"${areaids[i]}"`;
    }
    var days = ``;
    for (var i = 0; i < params.day.length; i++) {
        if (days.length > 0) {
            days = days + ", ";
        }
        days = days + `"${params.day[i]}"`;
    }
    var preparequery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\`;
        CREATE TABLE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\`
        CLUSTER BY geom
        AS (
            SELECT 
                h3,
                \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(i.h3) AS geom,
                COUNT(DISTINCT hashed_device_id) AS uniqueids,
                COUNT(DISTINCT id0) AS id0,
                COUNT(DISTINCT id2) AS id2,
                COUNT(DISTINCT id4) AS id4,
                COUNT(DISTINCT id6) AS id6,
                COUNT(DISTINCT id8) AS id8,
                COUNT(DISTINCT id10) AS id10,
                COUNT(DISTINCT id12) AS id12,
                COUNT(DISTINCT id14) AS id14,
                COUNT(DISTINCT id16) AS id16,
                COUNT(DISTINCT id18) AS id18,
                COUNT(DISTINCT id20) AS id20,
                COUNT(DISTINCT id22) AS id22,
            FROM
                (
                    SELECT *
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                    WHERE
                        day_of_week in (${days}) AND
                        date BETWEEN CAST("${params.startDate}" AS DATE FORMAT 'YYYY-MM-DD') AND CAST("${params.endDate}" AS DATE FORMAT 'YYYY-MM-DD')
                ) i 
            JOIN
                (
                    SELECT 
                        geometry
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\`
                    WHERE id IN (${areaidstring})
                ) l
            ON     
                ST_INTERSECTS(\`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(i.cel_h3), l.geometry)
            GROUP BY 
                h3
        )
    `;
    await createQueryJob(preparequery, credentials);
    var query = `
        SELECT 
            MAX(id0) AS idhour0,
            MAX(id2) AS idhour2,
            MAX(id4) AS idhour4,
            MAX(id6) AS idhour6,
            MAX(id8) AS idhour8,
            MAX(id10) AS idhour10,
            MAX(id12) AS idhour12,
            MAX(id14) AS idhour14,
            MAX(id16) AS idhour16,
            MAX(id18) AS idhour18,
            MAX(id20) AS idhour20,
            MAX(id22) AS idhour22
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.livingareatimeseriesidcount_${vision}_${country}_${city}\` 
    `;
    var result = await executeSQL({ credentials, query: query, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    return result;
}

export async function checkPolygonGeometryWellFormed(id, geometry, credentials) {
    var geometrystring = ``;
    for (var i = 0; i < geometry.length; i++) {
        if (!geometry[i].properties.id || id.includes(geometry[i].properties.id)) {
            var currpolygon = geometry[i];
            if (geometrystring.length > 0) {
                geometrystring = geometrystring + ', ';
            }
            geometrystring = geometrystring + `ST_GEOGFROMGEOJSON('${JSON.stringify(currpolygon.geometry)}')`;
        }
    }
    if (geometrystring.length > 0) {
        var querystring = `SELECT ${geometrystring}`;
        try {
            await executeSQL({ credentials, query: querystring, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        } catch (err) {
            return -1;
        }
    }
    return 0;
}

export async function replacePolygonGeometry(id, deletedid, geometry, polygontable, polygonnamecolumn, credentials) {
    var querystring = ``;
    var deleteidlist = ``;
    // for (var i = 0; i < id.length; i++) {
    //     if (deleteidlist.length > 0) {
    //         deleteidlist = deleteidlist + ", ";
    //     }
    //     deleteidlist = deleteidlist + `"${id[i]}"`;
    // }
    for (var i = 0; i < deletedid.length; i++) {
        if (deleteidlist.length > 0) {
            deleteidlist = deleteidlist + ", ";
        }
        deleteidlist = deleteidlist + `"${deletedid[i]}"`;
    }
    if (deleteidlist.length > 0) {
        querystring = `${querystring}
            DELETE FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
            WHERE
                visionid = "${vision}"
                AND id IN (${deleteidlist});
        `;
    }
    var insertedids = [];
    var valuestring = ``;
    var updatestring = ``;
    for (var i = 0; i < geometry.length; i++) {
        if (geometry[i].properties.id && !(polygonnamecolumn in geometry[i].properties)) {
            // return error if different type of polygon
            return -1;
        }

        if (geometry[i].properties.id && insertedids.includes(geometry[i].properties.id)) {
            // if encounter duplicate, skip and dont insert
            continue;
        }
        if (!geometry[i].properties.id) {
            insertedids.push(geometry[i].properties.id);
            var currgeom = geometry[i];
            if (valuestring.length > 0) {
                valuestring = valuestring + ', ';
            }
            valuestring = valuestring + `
                (
                    ST_GEOGFROMGEOJSON('${JSON.stringify(currgeom.geometry)}'),
                    ${currgeom.properties.id ? '"'+currgeom.properties.id+'"' : 'GENERATE_UUID()'},
                    "${vision}",
                    "${currgeom.properties.name}",
                    ${currgeom.properties.description ? '"'+currgeom.properties.description.replaceAll( /(?:\r\n|\r|\n)/g, '<br>' )+'"' : '""'},
                    ${currgeom.properties.createdat ? '"'+currgeom.properties.createdat+'"' : 'CURRENT_TIMESTAMP()'},
                    CURRENT_TIMESTAMP()
                )
            `;
        } else if (id.includes(geometry[i].properties.id)) {
            insertedids.push(geometry[i].properties.id);
            var currgeom = geometry[i];
            updatestring = updatestring + `
                UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                SET 
                    geometry = ST_GEOGFROMGEOJSON('${JSON.stringify(currgeom.geometry)}'),
                    ${polygonnamecolumn} = "${currgeom.properties.name}", 
                    description = ${currgeom.properties.description ? '"'+currgeom.properties.description.replaceAll( /(?:\r\n|\r|\n)/g, '<br>' )+'"' : '""'},
                    updatedat = CURRENT_TIMESTAMP()
                WHERE
                    visionid = "${vision}" AND id = "${currgeom.properties.id}";
            `;
        }
    }
    if (valuestring.length > 0) {
        querystring = `${querystring}
            INSERT INTO
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                (geometry, id, visionid, ${polygonnamecolumn}, description, createdat, updatedat) 
            VALUES
                ${valuestring}
            ;
            ${updatestring}
        `;
    } else if (updatestring.length > 0) {
        querystring = `${querystring}
            ${updatestring}
        `;
    }
    try {
        if (querystring.length > 0) {
            return await createQueryJob(querystring, credentials);
        }
    } catch (err) {
        return -1;
    }
    return 0;
}

export async function getNewlyCreatedIDs(geometry, polygontable, polygonnamecolumn, credentials) {
    var namestring = ``;
    for (var i = 0; i < geometry.length; i++) {
        if (!geometry[i].properties.id) {
            if (namestring.length > 0) {
                namestring = namestring + ", ";
            }
            namestring = namestring + `"${geometry[i].properties.name}"`;
        }
    }
    if (namestring.length === 0) {
        return [];
    }
    var getNewIdQuery = `
        SELECT
            id
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
        WHERE
            visionid = "${vision}"
            AND ${polygonnamecolumn} IN (${namestring});
    `;
    try {
        var result = await executeSQL({ credentials, query: getNewIdQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        var output = [];
        for (var i = 0; i < result.length; i++) {
            output.push(result[i].id);
        }
        return output;
    } catch (err) {
        return [];
    }
}

export async function enrichIPTotalVisits(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }
    var enrichquery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
            R'''
                SELECT
                    id,
                    geometry
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                WHERE
                    visionid = "${vision}"
                    AND id IN (${idlist})
            ''',
            'geometry',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                    visits,
                    touristid,
                    residentid,
                    workerid
                FROM
                    (
                        SELECT
                            h3,
                            date,
                            SUM(visits) AS visits,
                            COUNT(DISTINCT touristid) AS touristid,
                            COUNT(DISTINCT residentid) AS residentid,
                            COUNT(DISTINCT workerid) AS workerid
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                        GROUP BY 
                            h3, date
                    )
            ''',
            'geom',
            [('visits', 'avg'), ('touristid', 'avg'), ('residentid', 'avg'), ('workerid', 'avg')],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`']
        );
        UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
        SET
            main.avg_traffic = ROUND(CAST(val.visits_avg AS NUMERIC),2),
            main.tourist_count = ROUND(CAST(val.touristid_avg AS NUMERIC),2), 
            main.resident_count = ROUND(CAST(val.residentid_avg AS NUMERIC),2),
            main.worker_count = ROUND(CAST(val.workerid_avg AS NUMERIC),2)
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\` val
        WHERE
            main.id = val.id
            AND main.visionid = "${vision}";
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`;
    `;
    if (country.toUpperCase() == "HONG_KONG_SAR" && city.toUpperCase() == "HONG_KONG") {
        enrichquery = `
            DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`;
            CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                R'''
                    SELECT
                        id,
                        geometry
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                    WHERE
                        visionid = "${vision}"
                        AND id IN (${idlist})
                ''',
                'geometry',
                R'''
                    SELECT
                        \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                        SUM(visits) AS visits,
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                    GROUP BY 
                        h3
                ''',
                'geom',
                [('visits', 'sum'), ('visits', 'avg')],
                ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`']
            );
            UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
            SET
                main.avg_traffic = ROUND(CAST(val.visits_avg AS NUMERIC),2), 
                main.tourist_count = CAST(0 AS NUMERIC), 
                main.resident_count = ROUND(CAST(val.visits_sum AS NUMERIC),2),
                main.worker_count = CAST(0 AS NUMERIC)
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\` val
            WHERE
                main.id = val.id
                AND main.visionid = "${vision}";
            DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_totalvisitcount_${vision}\`;
        `;
    }
    try {
        return await createQueryJob(enrichquery, credentials);
    } catch (err) {
        return -1;
    }
}

export async function enrichWorkerResidentUniqueCount(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    if (country.toUpperCase() == "HONG_KONG_SAR" && city.toUpperCase() == "HONG_KONG") {
        return 0;
    }

    var checkWorkerResidentCountTableQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN ('workercount_${country}_${city}_${vision}', 'residentcount_${country}_${city}_${vision}')
        ;
    `;

    var enrichQuery = ``;
    enrichQuery = `${enrichQuery}
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_worklocationdetails_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS_RAW(
            R'''
                SELECT
                    id,
                    geometry
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                WHERE
                    visionid = "${vision}"
                    AND id IN (${idlist})
            ''',
            'geometry',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(cdl_h3) AS geom,
                    hashed_device_id
                FROM
                    (
                        SELECT DISTINCT
                            cdl_h3, 
                            hashed_device_id
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                    )
            ''',
            'geom',
            ['hashed_device_id'],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_worklocationdetails_${vision}\`']
        ); 
    `;
    enrichQuery = `${enrichQuery}
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_homelocationdetails_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS_RAW(
            R'''
                SELECT
                    id,
                    geometry
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                WHERE
                    visionid = "${vision}"
                    AND id IN (${idlist})
            ''',
            'geometry',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(cel_h3) AS geom,
                    hashed_device_id
                FROM
                    (
                        SELECT DISTINCT
                            cel_h3, 
                            hashed_device_id
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                    )
            ''',
            'geom',
            ['hashed_device_id'],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_homelocationdetails_${vision}\`']
        ); 
    `;
    enrichQuery = `${enrichQuery}
        UPDATE 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
        SET
            main.resident_count = val.residentcount, 
            main.worker_count = val.workercount, 
            main.both_resident_and_worker_count = val.bothresidentandworkercount
        FROM
            (
                SELECT
                    id,
                    SUM(CASE WHEN iswork IS NOT NULL AND ishome IS NOT NULL THEN 1 ELSE 0 END) AS bothresidentandworkercount, 
                    SUM(CASE WHEN iswork IS NULL AND ishome IS NOT NULL THEN 1 ELSE 0 END) AS residentcount,
                    SUM(CASE WHEN ishome IS NULL AND iswork IS NOT NULL THEN 1 ELSE 0 END) AS workercount
                FROM
                    (
                        SELECT
                            CASE WHEN w.id IS NULL THEN h.id ELSE w.id END AS id, 
                            CASE WHEN w.hashed_device_id IS NULL THEN h.hashed_device_id ELSE w.hashed_device_id END AS hashed_device_id, 
                            iswork, 
                            ishome
                        FROM
                            (
                                SELECT DISTINCT
                                    t.id,
                                    en.hashed_device_id,
                                    "YES" AS iswork 
                                FROM
                                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_worklocationdetails_${vision} t, UNNEST(__carto_enrichment) en
                            ) w
                        FULL OUTER JOIN
                            (
                                SELECT DISTINCT
                                    t.id,
                                    en.hashed_device_id,
                                    "YES" AS ishome 
                                FROM
                                  \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_homelocationdetails_${vision} t, UNNEST(__carto_enrichment) en
                            ) h
                        ON 
                            w.id = h.id 
                            AND w.hashed_device_id = h.hashed_device_id
                    )
                GROUP BY    
                    id
            ) val
        WHERE 
            main.id = val.id 
            AND main.visionid = "${vision}";
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_worklocationdetails_${vision}\`;
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_homelocationdetails_${vision}\`;
    `;

    try {
        var workerresidentcounttableexists = await executeSQL({ credentials, query: checkWorkerResidentCountTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        if (workerresidentcounttableexists.length > 0) {
            enrichQuery = ``;
            if (workerresidentcounttableexists[0].table_name === `workercount_${country}_${city}_${vision}` 
                || (workerresidentcounttableexists.length > 1 && workerresidentcounttableexists[1].table_name === `workercount_${country}_${city}_${vision}`) ) {
                enrichQuery = `${enrichQuery}
                    DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_workerdetails_${vision}\`;
                    CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                        R'''SELECT id, geometry FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                            WHERE visionid = "${vision}" AND id IN (${idlist})
                        ''',
                        'geometry',
                        R'''SELECT geom, total_nr_of_guests FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.workercount_${country}_${city}_${vision}\`''',
                        'geom',
                        [('total_nr_of_guests', 'sum')],
                        ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_workerdetails_${vision}\`']
                    ); 
                `;
                enrichQuery = `${enrichQuery}
                    UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                    SET main.worker_count = CAST(val.total_nr_of_guests_sum AS NUMERIC)
                    FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_workerdetails_${vision}\` val
                    WHERE main.id = val.id AND main.visionid = "${vision}";
                    DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_workerdetails_${vision}\`;
                `;
            }
            if (workerresidentcounttableexists[0].table_name === `residentcount_${country}_${city}_${vision}` 
                || (workerresidentcounttableexists.length > 1 && workerresidentcounttableexists[1].table_name === `residentcount_${country}_${city}_${vision}`) ) {
                enrichQuery = `${enrichQuery}
                    DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_residentdetails_${vision}\`;
                    CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                        R'''SELECT id, geometry FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                            WHERE visionid = "${vision}" AND id IN (${idlist})
                        ''',
                        'geometry',
                        R'''SELECT geom, total_nr_of_guests FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.residentcount_${country}_${city}_${vision}\`''',
                        'geom',
                        [('total_nr_of_guests', 'sum')],
                        ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_residentdetails_${vision}\`']
                    ); 
                `;
                enrichQuery = `${enrichQuery}
                    UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                    SET main.resident_count = CAST(val.total_nr_of_guests_sum AS NUMERIC)
                    FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_residentdetails_${vision}\` val
                    WHERE main.id = val.id AND main.visionid = "${vision}";
                    DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_residentdetails_${vision}\`;
                `;
            }
        }
        return await createQueryJob(enrichQuery, credentials);
    } catch (err) {
        return -1;
    }
}

export async function enrichTouristUniqueCount(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    if (country.toUpperCase() == "HONG_KONG_SAR" && city.toUpperCase() == "HONG_KONG") {
        return 0;
    }

    var enrichQuery = ``;
    var checkTouristCountTableQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN ('touristcount_${country}_${city}_${vision}')
        ;
    `;
    try {
        var touristcounttableexists = await executeSQL({ credentials, query: checkTouristCountTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        if (touristcounttableexists.length > 0) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''SELECT id, geometry FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE visionid = "${vision}" AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''SELECT geom, total_nr_of_guests FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.touristcount_${country}_${city}_${vision}\`''',
                    'geom',
                    [('total_nr_of_guests', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`']
                ); 
            `;
            enrichQuery = `${enrichQuery}
                UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET main.tourist_count = CAST(val.total_nr_of_guests_sum AS NUMERIC)
                FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\` val
                WHERE main.id = val.id AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`;
            `;
        } else {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS_RAW(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                            hashed_device_id
                        FROM
                            (
                                SELECT DISTINCT
                                    h3, 
                                    hashed_device_id
                                FROM
                                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.SUMMARY_IP_${country}_${city}\`
                                WHERE
                                    type = "Tourist"
                            )
                    ''',
                    'geom',
                    ['hashed_device_id'],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`']
                ); 
            `;
            enrichQuery = `${enrichQuery}
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.tourist_count = val.touristcount
                FROM
                    (
                        SELECT
                            id,
                            COUNT(DISTINCT hashed_device_id) AS touristcount
                        FROM
                            (
                                SELECT DISTINCT 
                                    t.id,
                                    en.hashed_device_id
                                FROM
                                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_touristdetails_${vision} t, UNNEST(__carto_enrichment) en
                            )
                        GROUP BY    
                            id
                    ) val
                WHERE 
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_touristdetails_${vision}\`;
            `;
        }
        return await createQueryJob(enrichQuery, credentials);
    } catch (err) {
        return -1;
    }
}

export async function enrichMainDemography(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var checkDemographyGeomQuery = `
        SELECT
            table_name
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name = "demographygeom_${vision}_${country}_${city}";
    `;
    try {
        var output = await executeSQL({ credentials, query: checkDemographyGeomQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        var enrichQuery = ``;
        if (output.length > 0) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            total_population AS total_population, 
                            purchasingpowerpercapita AS purchasingpowerpercapita,
                            purchasingpowerindex AS purchasingpowerindex,
                            agebelow15pct AS agebelow15pct, 
                            age15to59pct AS age15to59pct, 
                            age60abovepct AS age60abovepct 
                        FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demographygeom_${vision}_${country}_${city}\`
                    ''',
                    'geom',
                    [('total_population', 'sum'), ('purchasingpowerpercapita', 'avg'), ('purchasingpowerindex', 'avg'), ('agebelow15pct', 'avg'), ('age15to59pct', 'avg'), ('age60abovepct', 'avg')], 
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\`']
                );
            `;
        } else {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                            total_population_sum AS total_population, 
                            purchasingpowerpercapita_avg AS purchasingpowerpercapita,
                            purchasingpowerindex_avg AS purchasingpowerindex,
                            agebelow15pct_avg AS agebelow15pct, 
                            age15to59pct_avg AS age15to59pct, 
                            age60abovepct_avg AS age60abovepct 
                        FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
                    ''',
                    'geom',
                    [('total_population', 'sum'), ('purchasingpowerpercapita', 'avg'), ('purchasingpowerindex', 'avg'), ('agebelow15pct', 'avg'), ('age15to59pct', 'avg'), ('age60abovepct', 'avg')], 
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\`']
                );
            `;
        }
        enrichQuery = `${enrichQuery}
            UPDATE 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
            SET
                main.total_population = ROUND(CAST(val.total_population_sum AS NUMERIC),2), 
                main.population_density = ROUND(CAST(val.total_population_sum * 1000000 / ST_AREA(val.geometry) AS NUMERIC),2),
                main.wealth_index = ROUND(CAST(val.purchasingpowerpercapita_avg AS NUMERIC),2), 
                main.purchasingpowerindex = ROUND(CAST(val.purchasingpowerindex_avg AS NUMERIC),2), 
                main.medianage = ROUND(CAST((7*val.agebelow15pct_avg) + (37*age15to59pct_avg) + (60*age60abovepct_avg) AS NUMERIC),2)
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\` val
            WHERE
                main.id = val.id 
                AND main.visionid = "${vision}";
            DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_demographydetails_${vision}\`;
        `;
        return await createQueryJob(enrichQuery, credentials);
    } catch (err) {
        return -1;
    }
}

export async function enrichGender(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var checkTableQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name IN ("gender_${vision}_${country}_${city}", "gender_${country}_${city}", "gender_${country}", "demography_${vision}_${country}_${city}", "demography_${country}_${city}")
            AND column_name IN ('pop_female', 'pop_male');
    `;
    try {
        var gendertabletouse = ``;
        var output = await executeSQL({ credentials, query: checkTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        var gendertables = [`gender_${vision}_${country}_${city}`, `gender_${country}_${city}`, `gender_${country}`, `demography_${vision}_${country}_${city}`, `demography_${country}_${city}`];
        for (var i = 0; i < gendertables.length; i++) {
            for (var j = 0; j < output.length; j++) {
                if (output[j].table_name === gendertables[i]) {
                    gendertabletouse = gendertables[i];
                    break;
                }
            }
        }
        if (gendertabletouse.length === 0) {
            return 0;
        }

        var enrichQuery = `
            DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_genderdetails_${vision}\`;
            CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                R'''
                    SELECT
                        id,
                        geometry
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                    WHERE
                        visionid = "${vision}"
                        AND id IN (${idlist})
                ''',
                'geometry',
                R'''
                    SELECT 
                        geom, 
                        CAST(REPLACE(CAST(pop_male AS STRING), ',', '') AS NUMERIC) AS pop_male, 
                        CAST(REPLACE(CAST(pop_female AS STRING), ',', '') AS NUMERIC) AS pop_female 
                    FROM 
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.${gendertabletouse}
                ''',
                'geom',
                [('pop_male', 'sum'), ('pop_female', 'sum')],
                ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_genderdetails_${vision}\`']
            );
            UPDATE 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
            SET
                main.gender_m = ROUND(CAST(val.pop_male_sum AS NUMERIC),2), 
                main.gender_f = ROUND(CAST(val.pop_female_sum AS NUMERIC),2)
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_genderdetails_${vision}\` val
            WHERE
                main.id = val.id 
                AND main.visionid = "${vision}";
            DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_genderdetails_${vision}\`;
        `;
        await executeSQL({ credentials, query: enrichQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    } catch (error) {
        return -1;
    }
    return 0;
}

export async function enrichPois(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var poiList = [
        { name: "mall_count", tablename: `POI_Malls_${vision}_${country}_${city}`},
        { name: "supermarket_count", tablename: `POI_Supermarket_${vision}_${country}_${city}`},
        { name: "competitor_count", tablename: `POI_Competitors_${vision}_${country}_${city}`},
        { name: "sportscomplex_count", tablename: `POI_Sportsclubs_${vision}_${country}_${city}`},
        { name: "sportscomplex_count", tablename: `POI_Sportscomplex_${vision}_${country}_${city}`},
        { name: "commercialbldg_count", tablename: `POI_Commercialbuilding_${vision}_${country}_${city}`},
    ];
    var checkPoisQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN (
                "POI_Malls_${vision}_${country}_${city}", 
                "POI_Supermarket_${vision}_${country}_${city}", 
                "POI_Competitors_${vision}_${country}_${city}", 
                "POI_Sportsclubs_${vision}_${country}_${city}",
                "POI_Sportscomplex_${vision}_${country}_${city}",
                "POI_Commercialbuilding_${vision}_${country}_${city}"
            )
        ;
    `;
    try {
        var poiTables = []
        var results = await executeSQL({ credentials, query: checkPoisQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        for (var i = 0; i < results.length; i++) {
            poiTables.push(results[i].table_name);
        }

        var enrichQuery = ``;
        if (poiTables.includes(`POI_Malls_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_mallcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Malls_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mallcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.mall_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mallcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mallcount_${vision}\`;
            `;
        }
        if (poiTables.includes(`POI_Supermarket_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_supermarketcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Supermarket_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_supermarketcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.supermarket_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_supermarketcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_supermarketcount_${vision}\`;
            `;
        }
        if (poiTables.includes(`POI_Competitors_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_competitorcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Competitors_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_competitorcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.competitor_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_competitorcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_competitorcount_${vision}\`;
            `;
        }
        if (poiTables.includes(`POI_Sportsclubs_${vision}_${country}_${city}`) && poiTables.includes(`POI_Sportscomplex_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_sportscomplexcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT *
                        FROM 
                            (
                                SELECT
                                    geom,
                                    1 AS count
                                FROM
                                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportsclubs_${vision}_${country}_${city}\`
                            )
                        UNION ALL
                            (
                                SELECT
                                    geom,
                                    1 AS count
                                FROM
                                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportscomplex_${vision}_${country}_${city}\`
                            )
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.sportscomplex_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`;
            `;
        } else if (poiTables.includes(`POI_Sportsclubs_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_sportscomplexcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportsclubs_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.sportscomplex_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`;
            `;
        } else if (poiTables.includes(`POI_Sportscomplex_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_sportscomplexcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportscomplex_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.sportscomplex_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_sportscomplexcount_${vision}\`;
            `;
        }
        if (poiTables.includes(`POI_Commercialbuilding_${vision}_${country}_${city}`)) {
            enrichQuery = `${enrichQuery}
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.temp_commercialbldgcount_${vision};
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT
                            geom,
                            1 AS count
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Commercialbuilding_${vision}_${country}_${city}\`
                    ''',
                    'geom', 
                    [('count', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_commercialbldgcount_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.commercialbldg_count = CAST(val.count_sum AS NUMERIC)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_commercialbldgcount_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_commercialbldgcount_${vision}\`;
            `;
        }
        if (enrichQuery.length > 0) {
            return await createQueryJob(enrichQuery, credentials);
        }
    } catch (error) {
        return -1;
    }
    return 0;
}

export async function enrichTurnover(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var checkTableQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name = "turnover_${vision}_${country}_${city}";
    `;
    try {
        var tableExists = await executeSQL({ credentials, query: checkTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        if (tableExists.length > 0) {
            var enrichQuery = `
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_turnoverdetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT 
                            geom, 
                            online_to, 
                            physical_to, 
                            online_transaction_id, 
                            physical_transaction_id
                        FROM 
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.turnover_${vision}_${country}_${city}\`
                    ''',
                    'geom',
                    [('online_to', 'sum'), ('physical_to', 'sum'), ('online_transaction_id', 'sum'), ('physical_transaction_id', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_turnoverdetails_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.decath_digital_to = ROUND(CAST(val.online_to_sum AS NUMERIC),2), 
                    main.decath_physical_to = ROUND(CAST(val.physical_to_sum AS NUMERIC),2),
                    main.avg_basket = ROUND(CAST( CASE WHEN val.physical_transaction_id_sum = 0 THEN 0 ELSE val.physical_to_sum/val.physical_transaction_id_sum END AS NUMERIC), 2),
                    main.avg_online_basket = ROUND(CAST( CASE WHEN val.online_transaction_id_sum = 0 THEN 0 ELSE val.online_to_sum/val.online_transaction_id_sum END AS NUMERIC), 2),
                    main.decath_visit_count = ROUND(CAST(val.physical_transaction_id_sum AS NUMERIC),2)
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_turnoverdetails_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_turnoverdetails_${vision}\`;
            `;
            await executeSQL({ credentials, query: enrichQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        }
    } catch (error) {
        return -1;
    }
    return 0;
}

export async function enrichSegment(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var checkTableQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name = "segments_${vision}_${country}_${city}";
    `;
    try {
        var tableExists = await executeSQL({ credentials, query: checkTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        if (tableExists.length > 0) {
            var enrichQuery = `
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_customersegmentdetails_${vision}\`;
                CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
                    R'''
                        SELECT
                            id,
                            geometry
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\`
                        WHERE
                            visionid = "${vision}"
                            AND id IN (${idlist})
                    ''',
                    'geometry',
                    R'''
                        SELECT 
                            geom, 
                            total_respondent, 
                            premium_letes, 
                            expert_letes, 
                            aspire_letes,
                            value_exercisers,
                            wisely_actives,
                            promo_actives,
                            easy_actives
                        FROM 
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.segments_${vision}_${country}_${city}\`
                    ''',
                    'geom',
                    [('total_respondent', 'sum'), ('premium_letes', 'sum'), ('expert_letes', 'sum'), ('aspire_letes', 'sum'), ('value_exercisers', 'sum'), ('wisely_actives', 'sum'), ('promo_actives', 'sum'), ('easy_actives', 'sum')],
                    ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_customersegmentdetails_${vision}\`']
                );
                UPDATE 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
                SET
                    main.total_segment_respondent = ROUND(CAST(val.total_respondent_sum AS NUMERIC),2), 
                    main.premium_letes_segment = ROUND(CAST(val.premium_letes_sum AS NUMERIC),2),
                    main.expert_letes_segment = ROUND(CAST(val.expert_letes_sum AS NUMERIC),2),
                    main.aspire_letes_segment = ROUND(CAST(val.aspire_letes_sum AS NUMERIC),2),
                    main.value_exercisers_segment = ROUND(CAST(val.value_exercisers_sum AS NUMERIC),2), 
                    main.wisely_actives_segment = ROUND(CAST(val.wisely_actives_sum AS NUMERIC),2), 
                    main.promo_actives_segment = ROUND(CAST(val.promo_actives_sum AS NUMERIC),2), 
                    main.easy_actives_segment = ROUND(CAST(val.easy_actives_sum AS NUMERIC),2), 
                    main.top_segment = CASE 
                        WHEN premium_letes_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'PREMIUM-LETES' 
                        WHEN expert_letes_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'EXPERT-LETES'
                        WHEN aspire_letes_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'ASPIRE-LETES'
                        WHEN value_exercisers_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'VALUE-EXERCISERS'
                        WHEN wisely_actives_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'WISELY-ACTIVES'
                        WHEN promo_actives_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'PROMO-ACTIVES'
                        WHEN easy_actives_sum = GREATEST(premium_letes_sum, expert_letes_sum, aspire_letes_sum, value_exercisers_sum, wisely_actives_sum, promo_actives_sum, easy_actives_sum) THEN 'EASY-ACTIVES'
                    END
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_customersegmentdetails_${vision}\` val
                WHERE
                    main.id = val.id 
                    AND main.visionid = "${vision}";
                DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_customersegmentdetails_${vision}\`;
            `;
            await executeSQL({ credentials, query: enrichQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        }
    } catch (error) {
        return -1;
    }
    return 0;
}

export async function enrichSportMarketSize(id, polygontable, credentials) {
    if (id.length === 0) {
        return 0;
    }
    var idlist = ``;
    for (var i = 0; i < id.length; i++) {
        if (idlist.length > 0) {
            idlist = idlist + ", ";
        }
        idlist = idlist + `"${id[i]}"`;
    }

    var checkTableQuery = `
        SELECT 
            *
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
        WHERE
            visionid = "${vision}";
    `;
    try {
        var cityboundaryresult = await executeSQL({ credentials, query: checkTableQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
        if (cityboundaryresult.length <= 0) {
            return -1;
        }
        if (!(cityboundaryresult[0].total_population > 0 && cityboundaryresult[0].wealth_index > 0 && cityboundaryresult[0].sport_market_size > 0)) {
            console.log("City boundary does not have population and purchasingpowerpercapita");
            return 0;
        }

        var enrichQuery = `
            UPDATE 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.${polygontable}\` main
            SET
                main.sport_market_size = CAST(ROUND((main.total_population * main.wealth_index * val.sport_market_size) / (val.total_population * val.wealth_index)) AS NUMERIC)
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\` val
            WHERE
                main.visionid = "${vision}"
                AND main.id IN (${idlist})
                AND main.visionid = val.visionid
                AND main.total_population > 0
                AND main.wealth_index > 0;
        `;
        await executeSQL({ credentials, query: enrichQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    } catch (error) {
        return -1;
    }
    return 0;
}