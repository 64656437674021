import * as React from 'react';
import { Autocomplete, Button, Card, CardActions, CardContent, Divider, FormControl, Grid, InputLabel, ListItem, MenuItem, Select, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { arearange, buildingtypes, checkTOEstimationExists, fetchAllTOEstimate, fetchModelPerformance, runModelPerformance, runTOEstimationModel, storeagerange, storeformats } from 'utils/toestimationUtil';
import { useSelector } from 'react-redux';
import { city, country } from 'utils/urlParamUtil';
import { DataGrid } from '@mui/x-data-grid';
import 'styles.css';
import { Button as BaseButton, buttonClasses } from '@mui/base/Button';
import { retrieveCityBoundary, retrieveLivingArea, retrieveOpportunityArea, retrieveStoreLocations } from 'utils/polygonUtil';
import { useDispatch } from 'react-redux';
import { addLayer, addSource, removeSource, setBasemap, setViewState, updateLayer } from '@carto/react-redux';
import { geocodeStreetPoint } from '@carto/react-widgets';
import { configureDemographyLayerConfig, configureIPLayersConfig, configurePurchasingPowerLayerConfig, configureTOEstimationLayerConfig, configureTurnoverLayersConfig, fetchIPDates, findAvailableLayerSource, getLayerConfig } from 'utils/layerUtil';
import toEstimationSource from 'data/sources/toestimationSource';
import { TO_ESTIMATION_LAYER_ID } from 'components/layers/TOEstimationLayer';
import demographySource from 'data/sources/demographySource';
import fraGirondeSpSource from 'data/sources/fraGirondeSpSource';
import purchasingpowerSource from 'data/sources/purchasingpowerSource';
import turnoverSource from 'data/sources/turnoverSource';
import segmentSource from 'data/sources/segmentSource';
import poiMallsSource from 'data/sources/POI/mallsSource';
import { POI_MALLS_LAYER_ID } from 'components/layers/POI/PoiMallsLayer';
import poiSportsclubsSource from 'data/sources/POI/sportsclubsSource';
import { POI_SPORTSCLUBS_LAYER_ID } from 'components/layers/POI/PoiSportsclubsLayer';
import poiSportscomplexSource from 'data/sources/POI/sportscomplexSource';
import { POI_SPORTSCOMPLEX_LAYER_ID } from 'components/layers/POI/PoiSportscomplexLayer';
import poiParksSource from 'data/sources/POI/parksSource';
import { POI_PARKS_LAYER_ID } from 'components/layers/POI/PoiParksLayer';
import poiStadiumSource from 'data/sources/POI/stadiumSource';
import { POI_STADIUM_LAYER_ID } from 'components/layers/POI/PoiStadiumLayer';
import poiSchoolSource from 'data/sources/POI/schoolSource';
import { POI_SCHOOL_LAYER_ID } from 'components/layers/POI/PoiSchoolLayer';
import poiUniversitySource from 'data/sources/POI/universitySource';
import { POI_UNIVERSITY_LAYER_ID } from 'components/layers/POI/PoiUniversityLayer';
import poiCompetitorsSource from 'data/sources/POI/competitorsSource';
import { POI_COMPETITORS_LAYER_ID } from 'components/layers/POI/PoiCompetitorsLayer';
import poiShoplotsSource from 'data/sources/POI/shoplotsSource';
import { POI_SHOPLOTS_LAYER_ID } from 'components/layers/POI/PoiShoplotsLayer';
import poiHighStreetretailSource from 'data/sources/POI/highstreetretailSource';
import { POI_HIGHSTREETRETAIL_LAYER_ID } from 'components/layers/POI/PoiHighStreetretailLayer';
import poiCommercialbuldingSource from 'data/sources/POI/commercialbuildingSource';
import { POI_COMMERCIALBUILDING_LAYER_ID } from 'components/layers/POI/PoiCommercialbuildingLayer';
import poiCBDSource from 'data/sources/POI/cbdSource';
import { POI_CBD_LAYER_ID } from 'components/layers/POI/PoiCBDLayer';
import poiSupermarketSource from 'data/sources/POI/supermarketSource';
import { POI_SUPERMARKET_LAYER_ID } from 'components/layers/POI/PoiSupermarketLayer';
import poiLogisticareaSource from 'data/sources/POI/logisticareaSource';
import { POI_LOGISTICAREA_LAYER_ID } from 'components/layers/POI/PoiLogisticareaLayer';
import poiMetrolinesSource from 'data/sources/POI/metrolinesSource';
import { POI_METROLINES_LAYER_ID } from 'components/layers/POI/PoiMetrolinesLayer';
import poiHighwaysSource from 'data/sources/POI/highwaysSource';
import { POI_HIGHWAYS_LAYER_ID } from 'components/layers/POI/PoiHighwaysLayer';
import poiManMadebarriersSource from 'data/sources/POI/manmadebarriersSource';
import { POI_MANMADEBARRIERS_LAYER_ID } from 'components/layers/POI/PoiManMadebarriersLayer';
import poiNaturalbarrierSource from 'data/sources/POI/naturalbarrierSource';
import { POI_NATURALBARRIER_LAYER_ID } from 'components/layers/POI/PoiNaturalbarrierLayer';
import poiAirportsSource from 'data/sources/POI/airportsSource';
import { POI_AIRPORTS_LAYER_ID } from 'components/layers/POI/PoiAirportsLayer';
import poiPortSource from 'data/sources/POI/portSource';
import { POI_PORT_LAYER_ID } from 'components/layers/POI/PoiPortLayer';
import poiTrainstationSource from 'data/sources/POI/trainstationSource';
import { POI_TRAINSTATION_LAYER_ID } from 'components/layers/POI/PoiTrainstationLayer';
import poiMajorbushubSource from 'data/sources/POI/majorbushubSource';
import { POI_MAJORBUSHUB_LAYER_ID } from 'components/layers/POI/PoiMajorbushubLayer';
import poiTouristareaSource from 'data/sources/POI/touristareaSource';
import { POI_TOURISTAREA_LAYER_ID } from 'components/layers/POI/PoiTouristareaLayer';
import poiFuturegrowthareaSource from 'data/sources/POI/futuregrowthareaSource';
import { POI_FUTUREGROWTHAREA_LAYER_ID } from 'components/layers/POI/PoiFuturegrowthareaLayer';
import poiIndustrialareaSource from 'data/sources/POI/industrialareaSource';
import { POI_INDUSTRIALAREA_LAYER_ID } from 'components/layers/POI/PoiIndustrialareaLayer';
import poiOtherPoiSource from 'data/sources/POI/otherpoiSource';
import { POI_OTHERPOI_LAYER_ID } from 'components/layers/POI/PoiOtherPoiLayer';
import poiAdminBoundariesSource from 'data/sources/POI/adminboundariesSource';
import { POI_ADMINBOUNDARIES_LAYER_ID } from 'components/layers/POI/PoiAdminBoundariesLayer';
import { fullNumberFormatter, numberFormatter } from 'utils/formatter';
import { Fragment } from 'react';
import { AllInclusive, Google, Map, PhotoSizeSelectSmall, Storefront } from '@mui/icons-material';
import { GOOGLE_ROADMAP, POSITRON } from '@carto/react-basemaps';

const NumberInput = React.forwardRef(function CustomerNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: 'increment',
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    )
});

const scoreColor = {
    30: "red",
    65: "gold",
    100: "green"
}

const columns = [
    { field: 'store_code', headerName: 'Store Code', width: 100 },
    {
        field: 'errorpercentage',
        headerName: 'Error Percentage(%)',
        width: 150,
        valueGetter: (value, row) => Math.round(value*10000)/100,
    },
];
const paginationModel = { page: 0, pageSize: 5 };
const storeFormatColumn = { field: 'store_format', headerName: 'Store Format', width: 120, headerClassName: 'h3details-header' };
const salesAreaColumn = { field: 'sales_area', headerName: 'Sales Area', width: 120, headerClassName: 'h3details-header' };
const estimatedTOColumn = {
    field: 'estimated_TO',
    headerName: 'Estimated TO (in local currency)',
    width: 180,
    headerClassName: 'h3details-header',
    valueFormatter: fullNumberFormatter
};


export default function ToEstimation() {
    const dispatch = useDispatch();
    const credentials = useSelector((state) => state.carto.credentials);
    const layerSources = useSelector((state) => state.layer.foundSource);
    const selectedHex = useSelector((state) => state.layer.selectedHex);
    const cityb = useSelector((state) => state.polygon.cityBoundary);
    const [brandAwarenessScore, setBrandAwarenessScore] = React.useState(50);
    const [selectedModel, setSelectedModel] = React.useState("");
    const [selectedModelScore, setSelectedModelScore] = React.useState(-1);
    const [isFetchingScore, setIsFetchingScore] = React.useState(false);
    const [storesAvailable, setStoresAvailable] = React.useState(true);
    const [modelScoreDate, setModelScoreDate] = React.useState("");
    const [modelDetails, setModelDetails] = React.useState([]);
    const [color, setColor] = React.useState("green");
    const [showDetails, setShowDetails] = React.useState(false);
    const [isFetchingEstimate, setIsFetchingEstimate] = React.useState(false);
    const [lastEstimateDate, setLastEstimateDate] = React.useState(null);
    const [visualizeValue, setVisualizeValue] = React.useState({
        "sales_area": 1500,
        "sales_area_class": "1001 - 2000",
        "store_format": '"Stand alone"',
        "store_age": 2,
    });
    const [allValue, setAllValue] = React.useState({});
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [storeFormatValue, setStoreFormatValue] = React.useState([]);
    const [salesAreaValue, setSalesAreaValue] = React.useState([]);
    const [tabValue, setTabValue] = React.useState(0);
    const [basemap, setBaseMap] = React.useState('POSITRON');

    React.useEffect(() => {
        retrieveCityBoundary(dispatch, credentials);
        retrieveOpportunityArea(dispatch, credentials);
        retrieveLivingArea(dispatch, credentials);
        retrieveStoreLocations(dispatch, credentials);
        const fetchGeo = async () => {
            var splitted = city.split('_');
            if (splitted.slice(-1)[0].toUpperCase() == "CITY") {
                splitted.pop();
            }
            var sanitizedCity = splitted.join('_');
            if (sanitizedCity.toUpperCase() === "ALLAHABAD") {
              sanitizedCity = "PRAYAGRAJ";
            }
            const result = await geocodeStreetPoint(credentials, {
                searchText: sanitizedCity.replaceAll('_', ' ') + ', ' + country.replaceAll('_', ' '),
                country: '',
            });
            dispatch(
                setViewState({
                    latitude: result.latitude,
                    longitude: result.longitude,
                    zoom: 8,
                    transitionDuration: 500,
                })
            );
        };
        fetchGeo();
        findAvailableLayerSource(dispatch, credentials);
        dispatch(addSource(toEstimationSource));
    }, []);

    React.useEffect(() => {
        if (layerSources.includes("IP")) {
            dispatch(addSource(fraGirondeSpSource));  
            configureIPLayersConfig(dispatch, credentials);
        }
        if (layerSources.includes("Demography")) {
            dispatch(addSource(demographySource));
            configureDemographyLayerConfig(dispatch, credentials);
        }
        if (layerSources.includes("PurchasingPower")) {
            dispatch(addSource(purchasingpowerSource));
            configurePurchasingPowerLayerConfig(dispatch, credentials);
        }
        if (layerSources.includes("Turnover")) {
            dispatch(addSource(turnoverSource));
            configureTurnoverLayersConfig(dispatch, credentials);
        }
        if (layerSources.includes("VOC")) {
            dispatch(addSource(segmentSource));
        }
        var poiList = [
            { "name": "POI_Malls", "source": poiMallsSource, "layerid": POI_MALLS_LAYER_ID },
            { "name": "POI_Sportsclubs", "source": poiSportsclubsSource, "layerid": POI_SPORTSCLUBS_LAYER_ID },
            { "name": "POI_Sportscomplex", "source": poiSportscomplexSource, "layerid": POI_SPORTSCOMPLEX_LAYER_ID },
            { "name": "POI_Parks", "source": poiParksSource, "layerid": POI_PARKS_LAYER_ID },
            { "name": "POI_Stadium", "source": poiStadiumSource, "layerid": POI_STADIUM_LAYER_ID },
            { "name": "POI_School", "source": poiSchoolSource, "layerid": POI_SCHOOL_LAYER_ID },
            { "name": "POI_University", "source": poiUniversitySource, "layerid": POI_UNIVERSITY_LAYER_ID },
            { "name": "POI_Competitors", "source": poiCompetitorsSource, "layerid": POI_COMPETITORS_LAYER_ID },
            { "name": "POI_Shoplots", "source": poiShoplotsSource, "layerid": POI_SHOPLOTS_LAYER_ID },
            { "name": "POI_HighStreetretail", "source": poiHighStreetretailSource, "layerid": POI_HIGHSTREETRETAIL_LAYER_ID },
            { "name": "POI_Commercialbuilding", "source": poiCommercialbuldingSource, "layerid": POI_COMMERCIALBUILDING_LAYER_ID },
            { "name": "POI_Supermarket", "source": poiSupermarketSource, "layerid": POI_SUPERMARKET_LAYER_ID },
            { "name": "POI_CBD", "source": poiCBDSource, "layerid": POI_CBD_LAYER_ID },
            { "name": "POI_Logisticarea", "source": poiLogisticareaSource, "layerid": POI_LOGISTICAREA_LAYER_ID },
            { "name": "POI_Metrolines", "source": poiMetrolinesSource, "layerid": POI_METROLINES_LAYER_ID },
            { "name": "POI_Highways", "source": poiHighwaysSource, "layerid": POI_HIGHWAYS_LAYER_ID },
            { "name": "POI_ManMadebarriers", "source": poiManMadebarriersSource, "layerid": POI_MANMADEBARRIERS_LAYER_ID },
            { "name": "POI_Naturalbarrier", "source": poiNaturalbarrierSource, "layerid": POI_NATURALBARRIER_LAYER_ID },
            { "name": "POI_Airports", "source": poiAirportsSource, "layerid": POI_AIRPORTS_LAYER_ID },
            { "name": "POI_Port", "source": poiPortSource, "layerid": POI_PORT_LAYER_ID },
            { "name": "POI_Trainstation", "source": poiTrainstationSource, "layerid": POI_TRAINSTATION_LAYER_ID },
            { "name": "POI_Majorbushub", "source": poiMajorbushubSource, "layerid": POI_MAJORBUSHUB_LAYER_ID },
            { "name": "POI_Touristarea", "source": poiTouristareaSource, "layerid": POI_TOURISTAREA_LAYER_ID },
            { "name": "POI_Futuregrowtharea", "source": poiFuturegrowthareaSource, "layerid": POI_FUTUREGROWTHAREA_LAYER_ID },
            { "name": "POI_Industrialarea", "source": poiIndustrialareaSource, "layerid": POI_INDUSTRIALAREA_LAYER_ID },
            { "name": "POI_OtherPoi", "source": poiOtherPoiSource, "layerid": POI_OTHERPOI_LAYER_ID },
            { "name": "POI_AdminBoundaries", "source": poiAdminBoundariesSource, "layerid": POI_ADMINBOUNDARIES_LAYER_ID },
        ];
        for (var i = 0; i < poiList.length; i++) {
            if (layerSources.includes(poiList[i].name)) {
                dispatch(addSource(poiList[i].source));
            }
        }
        return () => {
            dispatch(removeSource(fraGirondeSpSource.id));
            dispatch(removeSource(demographySource.id));
            dispatch(removeSource(purchasingpowerSource.id));
            dispatch(removeSource(turnoverSource.id));
            dispatch(removeSource(segmentSource.id));
            for (var i = 0; i < poiList.length; i++) {
                if (layerSources.includes(poiList[i].name)) {
                    dispatch(removeSource(poiList[i].source.id));
                }
            }
        };
      }, [dispatch, layerSources]);

    React.useEffect(() => {
        if (allValue[selectedHex]) {
            var newSelectedValue = [];
            var id = 0;
            for (var i = 0; i < allValue[selectedHex]["storeandarea"].length; i++) {
                newSelectedValue.push({...allValue[selectedHex]["storeandarea"][i], "id": id, "h3": selectedHex});
                id = id + 1;
            }
            setSelectedValue(newSelectedValue);
            var newStoreFormatValue = [];
            id = 0;
            for (const [key, val] of Object.entries(allValue[selectedHex]["storeformat"])) {
                newStoreFormatValue.push({"id": id, "h3": selectedHex, "store_format": key, "estimated_TO": val});
                id = id + 1;
            }
            setStoreFormatValue(newStoreFormatValue);
            var newSalesAreaValue = [];
            id = 0;
            for (const [key, val] of Object.entries(allValue[selectedHex]["salesarea"])) {
                newSalesAreaValue.push({"id": id, "h3": selectedHex, "sales_area": key, "estimated_TO": val});
                id = id + 1;
            }
            setSalesAreaValue(newSalesAreaValue);
        }
    }, [dispatch, selectedHex]);

    const onModelSelected = (event) => {
        setSelectedModel(event.target.value);
        setIsFetchingScore(true);
        var modelperformancePromise = fetchModelPerformance(credentials, event.target.value, country, city);
        modelperformancePromise.then( results => {
            setSelectedModelScore(results.score);
            setModelDetails(results.details);
            setModelScoreDate(results.date);
            for (var i = 0; i < Object.keys(scoreColor).length; i++) {
                if (results.score <= Object.keys(scoreColor)[i]) {
                    setColor(scoreColor[Object.keys(scoreColor)[i]]);
                    break;
                }
            }
            setIsFetchingScore(false);
        });
        var existingEstimatePromise = checkTOEstimationExists(credentials, event.target.value, country, city);
        existingEstimatePromise.then( found => {
            setLastEstimateDate(found);
        });
    }

    const calculateScore = () => {
        setIsFetchingScore(true);
        setStoresAvailable(true);
        var calculateScorePromise = runModelPerformance(credentials, selectedModel, country, city, brandAwarenessScore);
        calculateScorePromise.then( score => {
            if (score.details.length === 0) {
                setStoresAvailable(false);
                setIsFetchingScore(false);
                return;
            }
            setSelectedModelScore(score.score);
            setModelDetails(score.details);
            setModelScoreDate(score.date);
            for (var i = 0; i < Object.keys(scoreColor).length; i++) {
                if (score.score <= Object.keys(scoreColor)[i]) {
                    setColor(scoreColor[Object.keys(scoreColor)[i]]);
                    break;
                }
            }
            setIsFetchingScore(false);
        });
    }

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    }

    const estimateTO = () => {
        setIsFetchingEstimate(true);
        var estimateTOPromise = runTOEstimationModel(credentials, selectedModel, country, city, brandAwarenessScore, cityb[0]);
        estimateTOPromise.then( score => { 
            setLastEstimateDate(score);
            visualize();
            setIsFetchingEstimate(false);
        } )
    }

    const visualize = () => {
        var fetchAllPromise = fetchAllTOEstimate(credentials, selectedModel, country, city);
        fetchAllPromise.then( (allVal) => {
            setAllValue(allVal);
        } );
        const updatedSource = { ...toEstimationSource };
        const newQueryParams = {
            'modelName': `${selectedModel}`,
            'salesArea': visualizeValue.sales_area,
            'salesAreaClass': visualizeValue.sales_area_class,
            'storeFormat': visualizeValue.store_format.replaceAll('"', ''),
            'storeAge': 2
        };
        updatedSource.queryParameters = newQueryParams;
        dispatch(removeSource(toEstimationSource.id));
        dispatch(addSource(updatedSource));

        var layerConfigs = getLayerConfig(TO_ESTIMATION_LAYER_ID);
        var newConfig = layerConfigs[0]();
        layerConfigs[1]({...newConfig, visible: true});
        dispatch(addLayer({ id: TO_ESTIMATION_LAYER_ID, source: toEstimationSource.id }));
        configureTOEstimationLayerConfig(dispatch, credentials, newQueryParams);
        var currLayerConfig = layerConfigs[0]();
        dispatch( updateLayer(currLayerConfig) );
    }

    const onBasemapChange = (_, newBasemap) => {
        setBaseMap(newBasemap);
        if (newBasemap == 'POSITRON') {
            dispatch(setBasemap(POSITRON));
        } else if (newBasemap == 'GOOGLE_ROADMAP') {
            dispatch(setBasemap(GOOGLE_ROADMAP));
        } 
    };

    return (
        <Grid container spacing={2}>
            <Grid item sm={12}>
                <Card variant='outlined'>
                    <CardContent>
                        <Grid container>
                            <Grid item sm={8}>
                                <Typography variant='h6' component='div' sx={{lineHeight: "2.5em"}}>
                                    TO Estimation
                                </Typography>
                                <Typography sx={{ fontSize: 10, color: 'lightgrey' }}>
                                </Typography>
                            </Grid>
                            <Grid item sm={4}>
                                <ToggleButtonGroup
                                    size="small"
                                    value={basemap}
                                    exclusive
                                    onChange={onBasemapChange}
                                >
                                    <ToggleButton size="small" value='GOOGLE_ROADMAP'><Google size="small"/></ToggleButton>
                                    <ToggleButton size="small" value='POSITRON'><Map size="small"/></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item sm={12}>
                                <Divider sx={{marginTop: "25px", marginBottom: "25px", marginLeft: "-15px", marginRight: "-15px"}}/>
                                <Typography variant={'caption'} sx={{fontSize: "14px"}}>
                                    Please enter the Aided Awareness Score for the city:
                                </Typography>
                                <div>
                                    <NumberInput 
                                        aria-label="Quantity Input" 
                                        min={1} max={99} 
                                        value={brandAwarenessScore} 
                                        onChange={(event, val) => setBrandAwarenessScore(val)}
                                        sx={{float: "left", marginTop: "10px"}} 
                                        endAdornment={<InputAdornment> / 100  </InputAdornment>}
                                    />
                                </div>
                            </Grid>
                            <Grid item sm={12}  sx={{marginTop: "25px"}}>
                                <Typography variant={'caption'} sx={{fontSize: "14px"}}>
                                    Select from list of available models:
                                </Typography>
                                <FormControl fullWidth size="small" sx={{marginTop: "5px"}}>
                                    <InputLabel >Model</InputLabel>
                                    <Select labelId="toestimationmodel"
                                        id="toestimationmodelselect"
                                        value={selectedModel}
                                        label="Model"
                                        onChange={onModelSelected}
                                    >
                                        <MenuItem value="INDIA_TIER_1_CITIES">
                                            INDIA_TIER_1_CITIES
                                        </MenuItem>
                                        <MenuItem value="INDIA_TIER_1_NEW_PURCHASING_POWER">
                                            INDIA_TIER_1_NEW_PURCHASING_POWER
                                        </MenuItem>
                                        <MenuItem value="INDIA_TIER_1_NEW_PURCHASING_POWER_WITHOUT_OUTLIERS">
                                            INDIA_TIER_1_NEW_PURCHASING_POWER_WITHOUT_OUTLIERS
                                        </MenuItem>
                                        <MenuItem value="INDIA_TIER_1_MODEL4">
                                            INDIA_TIER_1_MODEL4
                                        </MenuItem>
                                        <MenuItem value="INDIA_TIER_1_MODEL5">
                                            INDIA_TIER_1_MODEL5
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} sx={{marginTop: "20px"}}>
                                {selectedModel !== "" && (
                                    <StyledCard variant="outlined">
                                        <CardContent>
                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                                Model Suitability Score
                                            </Typography>
                                            {isFetchingScore && (<div className='spinner' style={{marginTop: "25px"}}></div>)}
                                            {!isFetchingScore && selectedModelScore >= 0 && (
                                                <Typography id="modelscorevalue" gutterBottom sx={{ marginBottom: "0px", color: color, fontSize: 25, fontFamily: "system-ui", textAlign: "center", fontWeight: "bold"}}>
                                                    {Math.round(selectedModelScore*100)/100}%
                                                </Typography>
                                            )}
                                            {!isFetchingScore && selectedModelScore < 0 && (
                                                <Typography id="modelscorevalue" gutterBottom sx={{ marginBottom: "0px", color: 'grey', fontSize: 25, fontFamily: "system-ui", textAlign: "center", fontWeight: "bold"}}>
                                                    -
                                                </Typography>
                                            )}
                                            {!isFetchingScore && selectedModelScore < 0 && storesAvailable && (
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 10 }}>
                                                    Score not available yet. Click on the calculate score button to generate the model suitability score.
                                                </Typography>
                                            )}
                                            {!isFetchingScore && selectedModelScore < 0 && !storesAvailable && (
                                                <Typography gutterBottom sx={{ color: 'orange!important', fontSize: 10 }}>
                                                    No stores available in the model dataset. Model is not ready for this city.
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions>
                                            {!isFetchingScore && (
                                                <Button 
                                                    size="small" 
                                                    onClick={calculateScore}
                                                    sx={{fontSize: "x-small", fontWeight: "bold!important", fontFamily: "system-ui"}}
                                                >
                                                    Calculate Score
                                                </Button>
                                            )}
                                            {selectedModelScore >= 0 && !isFetchingScore && (
                                                <Button 
                                                    size="small" 
                                                    onClick={toggleDetails}
                                                    sx={{fontSize: "x-small", fontWeight: "bold!important", fontFamily: "system-ui", marginLeft: "30%!important"}}
                                                >
                                                    Show Details
                                                </Button>
                                            )}
                                        </CardActions>
                                    </StyledCard>
                                )}
                                {showDetails && (
                                    <PredictionCard>
                                        <CardContent>
                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 10 }}>
                                                Prediction Details
                                            </Typography>
                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 8 }}>
                                                Date generated: {modelScoreDate}
                                            </Typography>
                                            <DataGrid 
                                                getRowId={(row) => row.store_code}
                                                rows={modelDetails}
                                                columns={columns}
                                                initialState={{ pagination: { paginationModel } }}
                                                pageSizeOptions={[5]}
                                                sx={{fontSize: "x-small"}}
                                                disableRowSelectionOnClick
                                                rowHeight={25}
                                            />
                                        </CardContent>
                                    </PredictionCard>
                                )}
                            </Grid>
                            {!isFetchingScore && selectedModelScore >= 0 && (
                                <React.Fragment>
                                    <Grid item sm={12}>
                                        <Divider sx={{marginTop: "25px", marginBottom: "25px", marginLeft: "-15px", marginRight: "-15px"}}/>
                                        {isFetchingEstimate && (<div className='spinner' style={{marginTop: "25px"}}></div>)}
                                    </Grid>
                                    {!isFetchingEstimate && lastEstimateDate && (
                                        <React.Fragment>
                                            <Grid item sm={12}>
                                                <Typography gutterBottom sx={{ color: 'black', fontSize: 15, lineHeight: 2.5 }}>
                                                    Estimation Store Details
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 15, lineHeight: 2.5 }}>
                                                    Sales Area:
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={6}>
                                                <FormControl variant='standard' sx={{width: "100%"}}>
                                                    <Select variant='standard'
                                                        value={visualizeValue.sales_area_class}
                                                        onChange={(e) => {setVisualizeValue({ ...visualizeValue, sales_area: arearange[e.target.value], sales_area_class: e.target.value }) }}
                                                        label='Sales Area:'
                                                        sx={{fontSize: 12, lineHeight: 2.5}}
                                                    >
                                                        {Object.keys(arearange).map((area, idx) => {
                                                            return (
                                                                <MenuItem key={"area"+toString(idx)} value={area}>
                                                                    {area}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sm={5}>
                                                <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 15, lineHeight: 2.5 }}>
                                                    Store Format:
                                                </Typography>
                                            </Grid>
                                            <Grid item sm={7}>
                                                <FormControl variant='standard' sx={{width: "100%"}}>
                                                    <Select variant='standard'
                                                        value={visualizeValue.store_format}
                                                        onChange={(e) => setVisualizeValue({ ...visualizeValue, store_format: e.target.value }) }
                                                        label='Store Format:'
                                                        sx={{fontSize: 12, lineHeight: 2.5}}
                                                    >
                                                        {storeformats.map((format, idx) => {
                                                            if (format !== '"Franchise"') {
                                                                return (
                                                                    <MenuItem  value={format}>
                                                                        {format}
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                    <Grid item sm={6} sx={{marginTop: "15px"}}>
                                        {!isFetchingEstimate && lastEstimateDate && (
                                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 8, float: "right", textAlign: "end" }}>
                                                Estimate generated on: <br /> {lastEstimateDate}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item sm={6} sx={{marginTop: "15px"}}>
                                        {!isFetchingEstimate && lastEstimateDate && (
                                            <StyledBaseButton 
                                                onClick={visualize}
                                                sx={{ backgroundColor: "green", marginLeft: "10px", '&:hover': { backgroundColor: "darkgreen" } }}
                                            >
                                                Visualize TO Estimation
                                            </StyledBaseButton>
                                        )}
                                    </Grid>
                                    <Grid item sm={6}>
                                    </Grid>
                                    <Grid item sm={6} sx={{marginTop: "10px"}}>
                                        {!isFetchingEstimate && (
                                            <StyledBaseButton 
                                                onClick={estimateTO} 
                                                sx={{ marginLeft: "10px" }}
                                            >
                                                Generate New Estimation
                                            </StyledBaseButton>
                                        )}
                                    </Grid>
                                </React.Fragment>
                            )}
                            <Grid item sm={12}>
                                <Divider sx={{marginTop: "25px", marginBottom: "25px", marginLeft: "-15px", marginRight: "-15px"}}/>
                                {selectedHex && selectedValue.length > 0 && !isFetchingEstimate && (
                                    <Fragment>
                                        <Tabs
                                            indicatorColor='secondary'
                                            textColor='inherit'
                                            value={tabValue}
                                            onChange={(e, val) => {setTabValue(val)}}
                                            sx={{marginBottom: "10px", backgroundColor: "#FF7900", color: "white!important", marginLeft: "-15px"}}
                                        >
                                            <Tooltip title='Store Format'>
                                                <Tab icon={<Storefront fontSize='small' />} />
                                            </Tooltip>
                                            <Tooltip title='Sales Area'>
                                                <Tab icon={<PhotoSizeSelectSmall fontSize='small' />} />
                                            </Tooltip>
                                            <Tooltip title='Store Format & Sales Area'>
                                                <Tab icon={<AllInclusive fontSize='small' />} />
                                            </Tooltip>
                                        </Tabs>
                                        <div style={{ height: 400, width: '100%' }}>
                                            {tabValue === 0 && (
                                                <Fragment>
                                                    <Typography variant='h6' sx={{fontSize: "small", fontWeight: "bold", marginLeft: "-10px"}}>Estimated Turnover (TO) by Store Format:</Typography>
                                                    <Typography variant='subtitle' sx={{fontSize: "x-small", color: "grey", marginLeft: "-10px"}}>Displays the estimated Year 1 turnover (local currency) for various store formats in the selected hexbin.</Typography>
                                                    <DataGrid 
                                                        getRowId={(row) => row.id}
                                                        rows={storeFormatValue}
                                                        columns={[storeFormatColumn, estimatedTOColumn]}
                                                        initialState={{ pagination:  { paginationModel: { pageSize: 10 } } , sorting: { sortModel: [{ field: "estimated_TO", sort: 'desc' }] } }}
                                                        pageSizeOptions={[10]}
                                                        sx={{fontSize: "x-small", marginLeft: "-15px"}}
                                                        disableRowSelectionOnClick
                                                        rowHeight={25}
                                                    />
                                                </Fragment>
                                            )}
                                            {tabValue === 1 && (
                                                <Fragment>
                                                    <Typography variant='h6' sx={{fontSize: "small", fontWeight: "bold", marginLeft: "-10px"}}>Estimated Turnover (TO) by Sales Area:</Typography>
                                                    <Typography variant='subtitle' sx={{fontSize: "x-small", color: "grey", marginLeft: "-10px"}}>Displays the estimated Year 1 turnover (local currency) for a store in the selected hexbin, broken down by sales area categories</Typography>
                                                    <DataGrid 
                                                        getRowId={(row) => row.id}
                                                        rows={salesAreaValue}
                                                        columns={[salesAreaColumn, estimatedTOColumn]}
                                                        initialState={{ pagination:  { paginationModel: { pageSize: 10 } } , sorting: { sortModel: [{ field: "estimated_TO", sort: 'desc' }] } }}
                                                        pageSizeOptions={[10]}
                                                        sx={{fontSize: "x-small", marginLeft: "-15px"}}
                                                        disableRowSelectionOnClick
                                                        rowHeight={25}
                                                    />
                                                </Fragment>
                                            )}
                                            {tabValue === 2 && (
                                                <Fragment>
                                                    <Typography variant='h6' sx={{fontSize: "small", fontWeight: "bold", marginLeft: "-10px"}}>Estimated Turnover (TO) by Store Format and Size:</Typography>
                                                    <Typography variant='subtitle' sx={{fontSize: "x-small", color: "grey", marginLeft: "-10px"}}>Estimation of Y1 turnover in Local currency based on store format and size in the hexbin selected</Typography>
                                                    <DataGrid 
                                                        getRowId={(row) => row.id}
                                                        rows={selectedValue}
                                                        columns={[storeFormatColumn, salesAreaColumn, estimatedTOColumn]}
                                                        initialState={{ pagination:  { paginationModel: { pageSize: 8 } } , sorting: { sortModel: [{ field: "estimated_TO", sort: 'desc' }] } }}
                                                        pageSizeOptions={[8]}
                                                        sx={{fontSize: "x-small", marginLeft: "-15px"}}
                                                        disableRowSelectionOnClick
                                                        rowHeight={25}
                                                    />
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                    
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

const StyledCard = styled(Card)(({ theme }) => ({
    // '& .MuiPaper-root': {
        width: "60%!important",
        position: 'relative!important',
        margin: "auto"
    // },
}));

const PredictionCard = styled(Card)(({ theme }) => ({
    // '& .MuiPaper-root': {
        width: "90%!important",
        position: 'relative!important',
        margin: "auto"
    // },
}));

const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
};
  
const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
};
  
const StyledInputRoot = styled('div')(
    ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 400;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
    `,
);
  
const StyledInput = styled('input')(
    ({ theme }) => `
        font-size: 0.8rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0 2px 4px ${
            theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
        };
        border-radius: 8px;
        margin: 0 10px;
        padding: 5px 12px;
        outline: 0;
        min-width: 0;
        width: 6rem;
        text-align: center;
        &:hover {
            border-color: ${blue[400]};
        }
        &:focus {
            border-color: ${blue[400]};
            box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
        }
        &:focus-visible {
            outline: 0;
        }
    `,
);
  
const StyledButton = styled('button')(
    ({ theme }) => `
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        box-sizing: border-box;
        line-height: 1.5;
        border: 1px solid;
        border-radius: 999px;
        border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
        color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
        width: 20px;
        height: 20px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
        &:hover {
            cursor: pointer;
            background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
            border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
            color: ${grey[50]};
        }
        &:focus-visible {
            outline: 0;
        }
        &.increment {
            order: 1;
        }
        .MuiSvgIcon-root {
            width: 0.8rem;
        }
    `,
);

const StyledBaseButton = styled(BaseButton)(
    ({ theme }) => `
        font-size: x-small!important;
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 500;
        font-size: 0.8rem;
        line-height: 1.5;
        background-color: ${blue[500]};
        padding: 4px 8px;
        border-radius: 8px;
        color: white;
        transition: all 150ms ease;
        cursor: pointer;
        border: 1px solid ${blue[500]};
        box-shadow: 0 2px 1px ${
            theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(45, 45, 60, 0.2)'
        }, inset 0 1.5px 1px ${blue[400]}, inset 0 -2px 1px ${blue[600]};

        &:hover {
            background-color: ${blue[600]};
        }

        &.${buttonClasses.active} {
            background-color: ${blue[700]};
            box-shadow: none;
            transform: scale(0.99);
        }

        &.${buttonClasses.focusVisible} {
            box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
            outline: none;
        }

        &.${buttonClasses.disabled} {
            background-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
            color: ${theme.palette.mode === 'dark' ? grey[200] : grey[700]};
            border: 0;
            cursor: default;
            box-shadow: none;
            transform: scale(1);
        }
    `,
);

const InputAdornment = styled('div')(
    ({ theme }) => `
        margin: 5px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        grid-row: 1/3;
        color: ${theme.palette.mode === 'dark' ? grey[500] : grey[700]};
        font-size: 12px;
        margin-right: 15px;
    `,
);