import { executeSQL } from "@carto/react-api";
import { createQueryJob, retrieveStoreLocations } from "./polygonUtil";
import { vision } from "./urlParamUtil";

export const modelMapping = {
    "INDIA_TIER_1_CITIES": {
        "<= 200": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "201 - 500": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "501 - 1000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "1001 - 2000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "2001 - 3000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "3001 - 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`,
        "> 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiatier1model`
    },
    "INDIA_TIER_1_NEW_PURCHASING_POWER": {
        "<= 200": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "201 - 500": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "501 - 1000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "1001 - 2000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "2001 - 3000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "3001 - 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`,
        "> 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1newmodel`
    },
    "INDIA_TIER_1_NEW_PURCHASING_POWER_WITHOUT_OUTLIERS": {
        "<= 200": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_200`,
        "201 - 500": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_500`,
        "501 - 1000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_1000`,
        "1001 - 2000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_2000`,
        "2001 - 3000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_3000`,
        "3001 - 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_4000`,
        "> 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_3000m_indiatier1withoutoutliermodel_4000`
    },
    "INDIA_TIER_1_MODEL4": {
        "<= 200": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class1`,
        "201 - 500": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class23`,
        "501 - 1000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class23`,
        "1001 - 2000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class4`,
        "2001 - 3000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class5`,
        "3001 - 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class67`,
        "> 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel4_class67`
    },
    "INDIA_TIER_1_MODEL5": {
        "<= 200": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class1`,
        "201 - 500": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class23`,
        "501 - 1000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class23`,
        "1001 - 2000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class4`,
        "2001 - 3000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class5`,
        "3001 - 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class67`,
        "> 4000": `vision-tool-4w7t.carto.bqml_toestimationmodel_indiamodel5_class67`
    }
}

export async function fetchModelPerformance(credentials, model, country, city) {
    var retrieveExistingModelPerformanceQuery = `
        SELECT
            store_code,
            actualto,
            ABS(predictto) AS predictto,
            errorpercentage / 4 AS errorpercentage,
            updatedon
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_tomodelevaluation\`
        WHERE
            modelname = "${model}"
            AND UPPER(country) = UPPER("${country}")
            AND UPPER(city) = UPPER("${city}")
        ORDER BY 
            store_code ASC
    `;
    var performanceResults = await executeSQL({ 
        credentials, 
        query: retrieveExistingModelPerformanceQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    if (performanceResults.length === 0) {
        return {"details": [], "score": -1};
    }
    var totalerror = 0;
    var totalto = 0;
    for (var i = 0; i < performanceResults.length; i++) {
        var predicted = performanceResults[i].predictto;
        if (model === "INDIA_TIER_1_CITIES") {
            predicted = predicted * 1000
            performanceResults[i]["errorpercentage"] = Math.abs(Math.abs(predicted) - performanceResults[i].actualto) / performanceResults[i].actualto
        }
        totalerror = totalerror + (Math.abs(predicted - performanceResults[i].actualto));
        totalto = totalto + performanceResults[i].actualto
    }
    var errorratio = totalerror / totalto;
    var score;
    console.log(totalerror);
    console.log(totalto);
    console.log(errorratio);
    if (errorratio > 10) {
        score = 0;
    } else if (errorratio <= 0.01) {
        score = 100;
    } else {
        var b = 40;
        var a = 20;
        score = a - b * Math.log(errorratio);
        if (score < 0) {
            score = 0;
        }
        if (score > 100) {
            score = 100;
        }
    }
    console.log(score);
    return {"details": performanceResults, "score": score, "date": performanceResults[0].updatedon};
}

export async function runModelPerformance(credentials, model, country, city, brandscore) {
    var prepareDemographyTableQuery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
            R'''
                SELECT 
                    ST_BUFFER(geom, 3000) AS geom, 
                    site_code AS store_code,
                    ST_AREA(ST_BUFFER(geom, 3000)) AS geomarea
                FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
                WHERE UPPER(country) = UPPER("${country}") AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
            ''',
            'geom',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                    total_population_sum AS total_population,
                    purchasingpowerpercapita_avg AS purchasingpowerpercapita,
                    agebelow15pct_avg AS agebelow15pct,
                    age15to59pct_avg AS age15to59pct,
                    age60abovepct_avg AS age60abovepct
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
            ''',
            'geom',
            [('total_population', 'sum'), ('purchasingpowerpercapita', 'avg'), ('agebelow15pct', 'avg'), ('age15to59pct', 'avg'), ('age60abovepct', 'avg')],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`']
        );
    `;
    await createQueryJob(prepareDemographyTableQuery, credentials);
    var demographyQuery = `
        SELECT 
            store_code, 
            total_population_sum AS totalpop, 
            total_population_sum * 1000000 / geomarea AS popdensity, 
            purchasingpowerpercapita_avg AS purchasingpowerpercapita,
            CAST(ROUND(CAST((7*agebelow15pct_avg) + (37*age15to59pct_avg) + (60*age60abovepct_avg) AS NUMERIC),2) AS INT64) AS medianage
        FROM   
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`
    `;
    
    var checkGenderQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name IN ("gender_${vision}_${country}_${city}", "gender_${country}_${city}", "gender_${country}", "demography_${vision}_${country}_${city}", "demography_${country}_${city}")
            AND column_name IN ('pop_female', 'pop_male');
    `;
    var gendertabletouse = ``;
    var output = await executeSQL({ credentials, query: checkGenderQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var gendertables = [`gender_${vision}_${country}_${city}`, `gender_${country}_${city}`, `gender_${country}`, `demography_${vision}_${country}_${city}`, `demography_${country}_${city}`];
    for (var i = 0; i < gendertables.length; i++) {
        for (var j = 0; j < output.length; j++) {
            if (output[j].table_name === gendertables[i]) {
                gendertabletouse = gendertables[i];
                break;
            }
        }
    }
    var genderQuery;
    if (gendertabletouse.length === 0) {
        genderQuery = `
            SELECT
                site_code AS store_code,
                0 AS popmale,
                0 AS popfemale,
            FROM 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
            WHERE 
                UPPER(country) = UPPER("${country}")
                AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
        `;
    } else {
        genderQuery = `
            SELECT 
                s.store_code,
                SUM(a.pop_male * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popmale,
                SUM(a.pop_female * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popfemale
            FROM
                \`vision-tool-4w7t.carto.${gendertabletouse}\` a
            JOIN
                (
                    SELECT
                        ST_BUFFER(geom, 3000) as geom,
                        site_code AS store_code
                    FROM 
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
                    WHERE 
                        UPPER(country) = UPPER("${country}")
                        AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY s.store_code
        `;
    }

    var poitables = [
        `"POI_Trainstation_${vision}_${country}_${city}"`,
        `"POI_Metrolines_${vision}_${country}_${city}"`,
        `"POI_Malls_${vision}_${country}_${city}"`,
        `"POI_Competitors_${vision}_${country}_${city}"`,
        `"POI_Sportsclubs_${vision}_${country}_${city}"`,
        `"POI_School_${vision}_${country}_${city}"`,
        `"POI_University_${vision}_${country}_${city}"`,
        `"POI_HighStreetretail_${vision}_${country}_${city}"`,
        `"POI_Stadium_${vision}_${country}_${city}"`,
    ];
    var checkPOIQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN (${poitables.join(", ")});
    `;
    var pois = await executeSQL({ 
        credentials, 
        query: checkPOIQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var trainstationQuery = `
        SELECT
            site_code AS store_code,
            0 AS trainstationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var metrostationQuery = `
        SELECT
            site_code AS store_code,
            0 AS metrostationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var mallQuery = `
        SELECT
            site_code AS store_code,
            0 AS mallcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var competitorQuery = `
        SELECT
            site_code AS store_code,
            0 AS competitorcount,
            0 AS pumacount,
            0 AS skechercount,
            0 AS adidascount,
            0 AS reebokcount,
            0 AS nikecount,
            0 AS jdsportcount,
            0 AS footlockercount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var sportsclubQuery = `
        SELECT
            site_code AS store_code,
            0 AS sportclubcount,
            0 AS gymcount,
            0 AS cricketcount,
            0 AS swimmingcount,
            0 AS footballcount,
            0 AS badmintoncount,
            0 AS tenniscount,
            0 AS golfcount,
            0 AS basketballcount,
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var schoolQuery = `
        SELECT
            site_code AS store_code,
            0 AS schoolcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var universityQuery = `
        SELECT
            site_code AS store_code,
            0 AS universitycount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var retailQuery = `
        SELECT
            site_code AS store_code,
            0 AS retailcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    var stadiumQuery = `
        SELECT
            site_code AS store_code,
            0 AS stadiumcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE 
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    for (var i = 0; i < pois.length; i++) {
        if (pois[i].table_name === `POI_Trainstation_${vision}_${country}_${city}`) {
            trainstationQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS trainstationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Trainstation_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_Metrolines_${vision}_${country}_${city}`) {
            metrostationQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS metrostationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Metrolines_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_Malls_${vision}_${country}_${city}`) {
            mallQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS mallcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Malls_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_School_${vision}_${country}_${city}`) {
            schoolQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS schoolcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_School_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_University_${vision}_${country}_${city}`) {
            universityQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS universitycount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_University_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_HighStreetretail_${vision}_${country}_${city}`) {
            retailQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS retailcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_HighStreetretail_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_Stadium_${vision}_${country}_${city}`) {
            stadiumQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS stadiumcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Stadium_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_Competitors_${vision}_${country}_${city}`) {
            competitorQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS competitorcount,
                    SUM( IF(UPPER(name) LIKE "%PUMA%", 1, 0) ) AS pumacount,
                    SUM( IF(UPPER(name) LIKE "%SKECHERS%" OR UPPER(name) LIKE "%SKETCHERS%", 1, 0) ) AS skechercount,
                    SUM( IF(UPPER(name) LIKE "%ADIDAS%" OR UPPER(name) LIKE "%ADDIDAS%", 1, 0) ) AS adidascount,
                    SUM( IF(UPPER(name) LIKE "%REEBOK%", 1, 0) ) AS reebokcount,
                    SUM( IF(UPPER(name) LIKE "%NIKE%", 1, 0) ) AS nikecount,
                    SUM( IF(UPPER(name) LIKE "%JD SPORT%" OR UPPER(name) LIKE "%JDSPORT%", 1, 0) ) AS jdsportcount,
                    SUM( IF(UPPER(name) LIKE "%FOOT LOCKER%" OR UPPER(name) LIKE "%FOOTLOCKER%", 1, 0) ) AS footlockercount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Competitors_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
        if (pois[i].table_name === `POI_Sportsclubs_${vision}_${country}_${city}`) {
            sportsclubQuery = `
                SELECT
                    s.site_code AS store_code,
                    COUNT(1) AS sportclubcount,
                    SUM( IF(UPPER(name) LIKE "%GYM%" OR UPPER(name) LIKE "%FITNESS%", 1, 0) ) AS gymcount,
                    SUM( IF(UPPER(name) LIKE "%CRICKET%", 1, 0) ) AS cricketcount,
                    SUM( IF(UPPER(name) LIKE "%SWIMMING%" OR UPPER(name) LIKE "%POOL%", 1, 0) ) AS swimmingcount,
                    SUM( IF(UPPER(name) LIKE "%FOOTBALL%", 1, 0) ) AS footballcount,
                    SUM( IF(UPPER(name) LIKE "%BADMINTON%", 1, 0) ) AS badmintoncount,
                    SUM( IF(UPPER(name) LIKE "%TENNIS%", 1, 0) ) AS tenniscount,
                    SUM( IF(UPPER(name) LIKE "%GOLF%", 1, 0) ) AS golfcount,
                    SUM( IF(UPPER(name) LIKE "%BASKETBALL%", 1, 0) ) AS basketballcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportsclubs_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(country) = UPPER("${country}")
                    AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                    AND ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.site_code
            `;
        }
    }

    var checkSegmentQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name = "segments_${vision}_${country}_${city}";
    `;
    var segmentExists = await executeSQL({ 
        credentials, 
        query: checkSegmentQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var segmentQuery = `
        SELECT 
            site_code AS store_code,
            CAST(0 AS NUMERIC) AS total_respondent,
            CAST(0 AS NUMERIC) AS percent_premiumletes,
            CAST(0 AS NUMERIC) AS percent_expertletes,
            CAST(0 AS NUMERIC) AS percent_aspireletes,
            CAST(0 AS NUMERIC) AS percent_valueexercisers,
            CAST(0 AS NUMERIC) AS percent_wiselyactives,
            CAST(0 AS NUMERIC) AS percent_promoactives,
            CAST(0 AS NUMERIC) AS percent_easyactives,
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\`
        WHERE
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
    `;
    if (segmentExists.length > 0) {
        segmentQuery = `
            SELECT 
                store_code,
                CAST(SUM(a.total_respondent * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS total_respondent,
                CAST(SUM(SAFE_DIVIDE(a.premium_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_premiumletes,
                CAST(SUM(SAFE_DIVIDE(a.expert_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_expertletes,
                CAST(SUM(SAFE_DIVIDE(a.aspire_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_aspireletes,
                CAST(SUM(SAFE_DIVIDE(a.value_exercisers, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_valueexercisers,
                CAST(SUM(SAFE_DIVIDE(a.wisely_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_wiselyactives,
                CAST(SUM(SAFE_DIVIDE(a.promo_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_promoactives,
                CAST(SUM(SAFE_DIVIDE(a.easy_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_easyactives,
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.segments_${vision}_${country}_${city}\` a
            JOIN
                (
                    SELECT
                        ST_BUFFER(geom, 3000) AS geom,
                        site_code AS store_code
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` 
                    WHERE
                        UPPER(country) = UPPER("${country}")
                        AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY store_code
        `;
    }

    var livingAreaQuery = `
        SELECT 
            site_code AS store_code,
            MAX(total_population) AS livingareatotalpop,
            MAX(population_density) AS livingareapopdensity,
            MAX(wealth_index) AS livingareapurchasingpower,
            MAX(avg_traffic) AS livingareafoottraffic,
            MAX(avg_basket) AS livingareabasketsize,
            MAX(sport_market_size) AS livingareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\` l
        JOIN
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
        ON
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
            AND l.visionid = "${vision}"
            AND ST_WITHIN(s.geom, l.geometry)
        GROUP BY site_code
    `;
    var oppAreaQuery = `
        SELECT 
            site_code AS store_code,
            MAX(total_population) AS oppareatotalpop,
            MAX(population_density) AS oppareapopdensity,
            MAX(wealth_index) AS oppareapurchasingpower,
            MAX(avg_traffic) AS oppareafoottraffic,
            MAX(avg_basket) AS oppareabasketsize,
            MAX(sport_market_size) AS oppareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_opportunityarea\` l
        JOIN
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
        ON
            UPPER(country) = UPPER("${country}")
            AND UPPER(REPLACE(city, " ", "_")) = UPPER("${city}")
            AND l.visionid = "${vision}"
            AND ST_WITHIN(s.geom, l.geometry)
        GROUP BY site_code
    `;
    var cityQuery = `
        SELECT 
            total_population AS citytotalpop,
            population_density AS citypopdensity,
            SAFE_DIVIDE(gender_m, gender_m+gender_f) AS citymaleratio,
            SAFE_DIVIDE(gender_f, gender_m+gender_f) AS cityfemaleratio,
            medianage AS citymedianage,
            wealth_index AS citypurchasingpower,
            sport_market_size AS citysportmarketsize,
            mall_count AS citymallcount,
            competitor_count AS citycompetitorcount,
            sportscomplex_count AS citysportcomplexcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
        WHERE 
            visionid = "${vision}"
    `;
    var localFxRateQuery = `
        SELECT
            rate_2025 AS local_currency_rate
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.FX_rates\`
        WHERE UPPER(country) = UPPER("${country}")
    `;
    
    var insertPerformanceQuery = ``;
    for (const [currsalesareaclass, modelname] of Object.entries(modelMapping[model])) {
        var storesQuery = `
            SELECT * FROM
            (
                SELECT 
                    UPPER(s.site_code) AS store_code,
                    CAST(sales_area AS NUMERIC) AS sales_area,
                    CASE WHEN (CAST(sales_area AS NUMERIC) <= 200) THEN "<= 200"
                        WHEN CAST(sales_area AS NUMERIC) <= 500 THEN "201 - 500"
                        WHEN CAST(sales_area AS NUMERIC) <= 1000 THEN "501 - 1000"
                        WHEN CAST(sales_area AS NUMERIC) <= 2000 THEN "1001 - 2000"
                        WHEN CAST(sales_area AS NUMERIC) <= 3000 THEN "2001 - 3000"
                        WHEN CAST(sales_area AS NUMERIC) <= 4000 THEN "3001 - 4000"
                        ELSE "> 4000" END AS sales_area_class,
                    CASE WHEN format_for_vision_app IS NULL THEN "Others" ELSE format_for_vision_app END AS store_format,
                    CASE WHEN EXTRACT(DAYOFYEAR FROM CURRENT_DATE) < EXTRACT(DAYOFYEAR FROM CAST(s.opening_date AS DATE FORMAT 'DD/MM/YYYY'))
                        THEN DATE_DIFF(CURRENT_DATE, CAST(s.opening_date AS DATE FORMAT 'DD/MM/YYYY'), YEAR)
                        ELSE DATE_DIFF(CURRENT_DATE, CAST(s.opening_date AS DATE FORMAT 'DD/MM/YYYY'), YEAR) + 1
                    END AS store_age,
                    SUM(total_sales_in_local_currency) AS real_physical_to
                FROM 
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.store_sales_ML\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.existingstorelocation_070125\` s
                ON
                    UPPER(s.country) = UPPER("${country}")
                    AND UPPER(REPLACE(s.city, " ", "_")) = UPPER("${city}")
                    AND UPPER(a.store_code) = UPPER(s.site_code)
                    AND DATE_DIFF(CURRENT_DATE, SAFE_CAST(s.opening_date AS DATE FORMAT 'DD/MM/YYYY'), YEAR) > 1
                GROUP BY
                    store_code,
                    sales_area,
                    store_format,
                    s.opening_date,
                    sales_area_class
            )
            WHERE sales_area_class = "${currsalesareaclass}"
        `;
        var storeCount = await executeSQL({ 
            credentials, 
            query: storesQuery, 
            connection: process.env.REACT_APP_CONNECTION_NAME, 
            opts: { cache: 'reload' } 
        });
        if (storeCount.length === 0) {
            continue;
        }
        insertPerformanceQuery = `${insertPerformanceQuery}
            INSERT INTO \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_tomodelevaluation\` (
                SELECT
                    "${model}" AS modelname,
                    UPPER("${country}") AS country,
                    UPPER("${city}") AS city,
                    store_code,
                    CAST((annual_turnover) / local_currency_rate AS NUMERIC) AS actualto,
                    CAST((predicted_annual_turnover) / local_currency_rate AS NUMERIC) AS predictto,
                    CAST(ABS(ABS(predicted_annual_turnover) - annual_turnover)/annual_turnover AS NUMERIC) AS errorpercentage,
                    CURRENT_TIMESTAMP() AS updatedon 
                FROM
                    ML.PREDICT(MODEL \`${modelname}\`, (
                        SELECT
                            3000 AS distance,
                            store_details.store_code,
                            "${vision}" AS visionid,
                            UPPER("${city}") AS city,
                            store_details.store_format,
                            store_details.store_age,
                            store_details.sales_area,
                            store_details.sales_area_class,
                            ROUND(CAST(store_details.real_physical_to * local_currency_rate AS NUMERIC)) AS annual_turnover,
                            ${brandscore} AS brandscore,
                            demo.totalpop,
                            CAST(demo.purchasingpowerpercapita AS NUMERIC) AS purchasingpowerpercapita,
                            demo.popdensity,
                            demo.medianage,
                            SAFE_DIVIDE(gender.popmale, (gender.popmale + gender.popfemale)) AS maleratio,
                            SAFE_DIVIDE(gender.popfemale, (gender.popmale + gender.popfemale)) AS femaleratio,
                            IFNULL(trainstationcount, 0) AS num_trainstations,
                            IFNULL(metrostationcount, 0) AS num_metrostations,
                            IFNULL(mallcount, 0) AS num_malls,
                            IFNULL(competitorcount, 0) AS num_competitors,
                            IFNULL(sportclubcount, 0) AS num_sportfacilities,
                            IFNULL(schoolcount, 0) AS num_schools,
                            IFNULL(universitycount, 0) AS num_university,
                            IFNULL(retailcount, 0) AS num_retails,
                            IFNULL(stadiumcount, 0) AS num_stadiums,
                            IFNULL(pumacount, 0) AS num_puma,
                            IFNULL(skechercount, 0) AS num_skecher,
                            IFNULL(adidascount, 0) AS num_adidas,
                            IFNULL(reebokcount, 0) AS num_reebok,
                            IFNULL(nikecount, 0) AS num_nike,
                            IFNULL(gymcount, 0) AS num_gym,
                            IFNULL(cricketcount, 0) AS num_cricket,
                            IFNULL(swimmingcount, 0) AS num_swimming,
                            IFNULL(footballcount, 0) AS num_football,
                            IFNULL(badmintoncount, 0) AS num_badminton,
                            IFNULL(tenniscount, 0) AS num_tennis,
                            IFNULL(golfcount, 0) AS num_golf,
                            IFNULL(basketballcount, 0) AS num_basketball,  
                            segment.total_respondent,
                            segment.percent_premiumletes,
                            segment.percent_expertletes,
                            segment.percent_aspireletes,
                            segment.percent_valueexercisers,
                            segment.percent_wiselyactives,
                            segment.percent_promoactives,
                            segment.percent_easyactives,
                            livingareatotalpop,
                            livingareapopdensity,
                            livingareapurchasingpower,
                            livingareafoottraffic,
                            livingareabasketsize,
                            livingareasportmarketsize,
                            oppareatotalpop,
                            oppareapopdensity,
                            oppareapurchasingpower,
                            oppareafoottraffic,
                            oppareabasketsize,
                            oppareasportmarketsize,
                            citytotalpop,
                            citypopdensity,
                            citymaleratio,
                            cityfemaleratio,
                            citymedianage,
                            CAST((citypurchasingpower) AS NUMERIC) AS citypurchasingpower,
                            CAST((citysportmarketsize) AS NUMERIC) AS citysportmarketsize,
                            citymallcount,
                            citycompetitorcount,
                            citysportcomplexcount,
                            local_currency_rate,
                        FROM
                            (${storesQuery}) store_details
                        LEFT JOIN
                            (${demographyQuery}) demo
                        ON demo.store_code = store_details.store_code
                        LEFT JOIN
                            (${genderQuery}) gender
                        ON gender.store_code = store_details.store_code
                        LEFT JOIN
                            (${trainstationQuery}) poi_trainstation
                        ON poi_trainstation.store_code = store_details.store_code
                        LEFT JOIN
                            (${metrostationQuery}) poi_metrostation
                        ON poi_metrostation.store_code = store_details.store_code
                        LEFT JOIN
                            (${mallQuery}) poi_mall
                        ON poi_mall.store_code = store_details.store_code
                        LEFT JOIN
                            (${competitorQuery}) poi_competitors
                        ON poi_competitors.store_code = store_details.store_code
                        LEFT JOIN
                            (${sportsclubQuery}) poi_sportclub
                        ON poi_sportclub.store_code = store_details.store_code
                        LEFT JOIN
                            (${schoolQuery}) poi_school
                        ON poi_school.store_code = store_details.store_code
                        LEFT JOIN
                            (${universityQuery}) poi_university
                        ON poi_university.store_code = store_details.store_code
                        LEFT JOIN
                            (${retailQuery}) poi_retail
                        ON poi_retail.store_code = store_details.store_code
                        LEFT JOIN
                            (${stadiumQuery}) poi_stadium
                        ON poi_stadium.store_code = store_details.store_code
                        LEFT JOIN
                            (${segmentQuery}) segment
                        ON segment.store_code = store_details.store_code
                        LEFT JOIN
                            (${livingAreaQuery}) livingarea
                        ON livingarea.store_code = store_details.store_code
                        LEFT JOIN
                            (${oppAreaQuery}) opparea
                        ON opparea.store_code = store_details.store_code
                        CROSS JOIN
                            (${cityQuery}) cityboundary
                        CROSS JOIN
                            (${localFxRateQuery}) localfx
                    ))
            );
        `;
    }
    if (insertPerformanceQuery.length === 0) {
        return {"details": [], "score": -1};
    }

    var modelPerformanceQuery = `
        DELETE FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_tomodelevaluation\` 
        WHERE modelname = "${model}" AND UPPER(country) = UPPER("${country}") AND UPPER(city) = UPPER("${city}");
        ${insertPerformanceQuery}
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`;
    `;
    await createQueryJob(modelPerformanceQuery, credentials);
    console.log(modelPerformanceQuery);
    var result = await fetchModelPerformance(credentials, model, country, city);
    return (result);
}

export async function estimateStoreTO(dispatch, credentials, stores, visionid, country, city) {
    var storeids = stores.map(s => (`"${s.properties.storeid}"`));
    console.log(storeids);
    var prepareDemographyTableQuery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
            R'''
                SELECT 
                    ST_BUFFER(geometry, 3000) AS geom, 
                    storeid AS store_code,
                    ST_AREA(ST_BUFFER(geometry, 3000)) AS geomarea
                FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
                WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
            ''',
            'geom',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                    total_population_sum AS total_population,
                    purchasingpowerpercapita_avg AS purchasingpowerpercapita,
                    agebelow15pct_avg AS agebelow15pct,
                    age15to59pct_avg AS age15to59pct,
                    age60abovepct_avg AS age60abovepct
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
            ''',
            'geom',
            [('total_population', 'sum'), ('purchasingpowerpercapita', 'avg'), ('agebelow15pct', 'avg'), ('age15to59pct', 'avg'), ('age60abovepct', 'avg')],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`']
        );
    `;
    await createQueryJob(prepareDemographyTableQuery, credentials);
    var demographyQuery = `
        SELECT 
            store_code, 
            total_population_sum AS totalpop, 
            total_population_sum * 1000000 / geomarea AS popdensity, 
            purchasingpowerpercapita_avg AS purchasingpowerpercapita,
            CAST(ROUND(CAST((7*agebelow15pct_avg) + (37*age15to59pct_avg) + (60*age60abovepct_avg) AS NUMERIC),2) AS INT64) AS medianage
        FROM   
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`
    `;
    var checkGenderQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name IN ("gender_${vision}_${country}_${city}", "gender_${country}_${city}", "gender_${country}", "demography_${vision}_${country}_${city}", "demography_${country}_${city}")
            AND column_name IN ('pop_female', 'pop_male');
    `;
    var gendertabletouse = ``;
    var output = await executeSQL({ credentials, query: checkGenderQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var gendertables = [`gender_${vision}_${country}_${city}`, `gender_${country}_${city}`, `gender_${country}`, `demography_${vision}_${country}_${city}`, `demography_${country}_${city}`];
    for (var i = 0; i < gendertables.length; i++) {
        for (var j = 0; j < output.length; j++) {
            if (output[j].table_name === gendertables[i]) {
                gendertabletouse = gendertables[i];
                break;
            }
        }
    }
    var genderQuery;
    if (gendertabletouse.length === 0) {
        genderQuery = `
            SELECT
                storeid AS store_code,
                0 AS popmale,
                0 AS popfemale,
            FROM 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
            WHERE 
                visionid = "${visionid}" and storeid IN (${storeids.join(",")});
        `;
    } else {
        genderQuery = `
            SELECT 
                s.store_code,
                SUM(a.pop_male * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popmale,
                SUM(a.pop_female * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popfemale
            FROM
                \`vision-tool-4w7t.carto.${gendertabletouse}\` a
            JOIN
                (
                    SELECT
                        ST_BUFFER(geometry, 3000) as geom,
                        storeid AS store_code
                    FROM 
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
                    WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY s.store_code
        `;
    }
    var poitables = [
        `"POI_Trainstation_${vision}_${country}_${city}"`,
        `"POI_Metrolines_${vision}_${country}_${city}"`,
        `"POI_Malls_${vision}_${country}_${city}"`,
        `"POI_Competitors_${vision}_${country}_${city}"`,
        `"POI_Sportsclubs_${vision}_${country}_${city}"`,
        `"POI_School_${vision}_${country}_${city}"`,
        `"POI_University_${vision}_${country}_${city}"`,
        `"POI_HighStreetretail_${vision}_${country}_${city}"`,
        `"POI_Stadium_${vision}_${country}_${city}"`,
    ];
    var checkPOIQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN (${poitables.join(", ")});
    `;
    var pois = await executeSQL({ 
        credentials, 
        query: checkPOIQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var trainstationQuery = `
        SELECT
            storeid AS store_code,
            0 AS trainstationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var metrostationQuery = `
        SELECT
            storeid AS store_code,
            0 AS metrostationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var mallQuery = `
        SELECT
            storeid AS store_code,
            0 AS mallcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var competitorQuery = `
        SELECT
            storeid AS store_code,
            0 AS competitorcount,
            0 AS pumacount,
            0 AS skechercount,
            0 AS adidascount,
            0 AS reebokcount,
            0 AS nikecount,
            0 AS jdsportcount,
            0 AS footlockercount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var sportsclubQuery = `
        SELECT
            storeid AS store_code,
            0 AS sportclubcount,
            0 AS gymcount,
            0 AS cricketcount,
            0 AS swimmingcount,
            0 AS footballcount,
            0 AS badmintoncount,
            0 AS tenniscount,
            0 AS golfcount,
            0 AS basketballcount,
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var schoolQuery = `
        SELECT
            storeid AS store_code,
            0 AS schoolcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var universityQuery = `
        SELECT
            storeid AS store_code,
            0 AS universitycount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var retailQuery = `
        SELECT
            storeid AS store_code,
            0 AS retailcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    var stadiumQuery = `
        SELECT
            storeid AS store_code,
            0 AS stadiumcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    for (var i = 0; i < pois.length; i++) {
        if (pois[i].table_name === `POI_Trainstation_${vision}_${country}_${city}`) {
            trainstationQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS trainstationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Trainstation_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_Metrolines_${vision}_${country}_${city}`) {
            metrostationQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS metrostationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Metrolines_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_Malls_${vision}_${country}_${city}`) {
            mallQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS mallcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Malls_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_School_${vision}_${country}_${city}`) {
            schoolQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS schoolcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_School_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_University_${vision}_${country}_${city}`) {
            universityQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS universitycount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_University_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_HighStreetretail_${vision}_${country}_${city}`) {
            retailQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS retailcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_HighStreetretail_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_Stadium_${vision}_${country}_${city}`) {
            stadiumQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS stadiumcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Stadium_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_Competitors_${vision}_${country}_${city}`) {
            competitorQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS competitorcount,
                    SUM( IF(UPPER(name) LIKE "%PUMA%", 1, 0) ) AS pumacount,
                    SUM( IF(UPPER(name) LIKE "%SKECHERS%" OR UPPER(name) LIKE "%SKETCHERS%", 1, 0) ) AS skechercount,
                    SUM( IF(UPPER(name) LIKE "%ADIDAS%" OR UPPER(name) LIKE "%ADDIDAS%", 1, 0) ) AS adidascount,
                    SUM( IF(UPPER(name) LIKE "%REEBOK%", 1, 0) ) AS reebokcount,
                    SUM( IF(UPPER(name) LIKE "%NIKE%", 1, 0) ) AS nikecount,
                    SUM( IF(UPPER(name) LIKE "%JD SPORT%" OR UPPER(name) LIKE "%JDSPORT%", 1, 0) ) AS jdsportcount,
                    SUM( IF(UPPER(name) LIKE "%FOOT LOCKER%" OR UPPER(name) LIKE "%FOOTLOCKER%", 1, 0) ) AS footlockercount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Competitors_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
        if (pois[i].table_name === `POI_Sportsclubs_${vision}_${country}_${city}`) {
            sportsclubQuery = `
                SELECT
                    s.storeid AS store_code,
                    COUNT(1) AS sportclubcount,
                    SUM( IF(UPPER(name) LIKE "%GYM%" OR UPPER(name) LIKE "%FITNESS%", 1, 0) ) AS gymcount,
                    SUM( IF(UPPER(name) LIKE "%CRICKET%", 1, 0) ) AS cricketcount,
                    SUM( IF(UPPER(name) LIKE "%SWIMMING%" OR UPPER(name) LIKE "%POOL%", 1, 0) ) AS swimmingcount,
                    SUM( IF(UPPER(name) LIKE "%FOOTBALL%", 1, 0) ) AS footballcount,
                    SUM( IF(UPPER(name) LIKE "%BADMINTON%", 1, 0) ) AS badmintoncount,
                    SUM( IF(UPPER(name) LIKE "%TENNIS%", 1, 0) ) AS tenniscount,
                    SUM( IF(UPPER(name) LIKE "%GOLF%", 1, 0) ) AS golfcount,
                    SUM( IF(UPPER(name) LIKE "%BASKETBALL%", 1, 0) ) AS basketballcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportsclubs_${vision}_${country}_${city}\` a
                JOIN
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
                ON
                    s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                    AND ST_DISTANCE(a.geom, s.geometry) <= 3000
                GROUP BY
                    s.storeid
            `;
        }
    }

    var checkSegmentQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name = "segments_${vision}_${country}_${city}";
    `;
    var segmentExists = await executeSQL({ 
        credentials, 
        query: checkSegmentQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var segmentQuery = `
        SELECT 
            storeid AS store_code,
            CAST(0 AS NUMERIC) AS total_respondent,
            CAST(0 AS NUMERIC) AS percent_premiumletes,
            CAST(0 AS NUMERIC) AS percent_expertletes,
            CAST(0 AS NUMERIC) AS percent_aspireletes,
            CAST(0 AS NUMERIC) AS percent_valueexercisers,
            CAST(0 AS NUMERIC) AS percent_wiselyactives,
            CAST(0 AS NUMERIC) AS percent_promoactives,
            CAST(0 AS NUMERIC) AS percent_easyactives,
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\`
        WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
    `;
    if (segmentExists.length > 0) {
        segmentQuery = `
            SELECT 
                store_code,
                CAST(SUM(a.total_respondent * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS total_respondent,
                CAST(SUM(SAFE_DIVIDE(a.premium_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_premiumletes,
                CAST(SUM(SAFE_DIVIDE(a.expert_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_expertletes,
                CAST(SUM(SAFE_DIVIDE(a.aspire_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_aspireletes,
                CAST(SUM(SAFE_DIVIDE(a.value_exercisers, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_valueexercisers,
                CAST(SUM(SAFE_DIVIDE(a.wisely_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_wiselyactives,
                CAST(SUM(SAFE_DIVIDE(a.promo_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_promoactives,
                CAST(SUM(SAFE_DIVIDE(a.easy_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_easyactives,
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.segments_${vision}_${country}_${city}\` a
            JOIN
                (
                    SELECT
                        ST_BUFFER(geometry, 3000) AS geom,
                        storeid AS store_code
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` 
                    WHERE visionid = "${visionid}" and storeid IN (${storeids.join(",")})
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY store_code
        `;
    }

    var livingAreaQuery = `
        SELECT 
            storeid AS store_code,
            MAX(total_population) AS livingareatotalpop,
            MAX(population_density) AS livingareapopdensity,
            MAX(wealth_index) AS livingareapurchasingpower,
            MAX(avg_traffic) AS livingareafoottraffic,
            MAX(avg_basket) AS livingareabasketsize,
            MAX(sport_market_size) AS livingareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\` l
        JOIN
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
        ON
            s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
            AND l.visionid = "${vision}"
            AND ST_WITHIN(s.geometry, l.geometry)
        GROUP BY storeid
    `;
    var oppAreaQuery = `
        SELECT 
            storeid AS store_code,
            MAX(total_population) AS oppareatotalpop,
            MAX(population_density) AS oppareapopdensity,
            MAX(wealth_index) AS oppareapurchasingpower,
            MAX(avg_traffic) AS oppareafoottraffic,
            MAX(avg_basket) AS oppareabasketsize,
            MAX(sport_market_size) AS oppareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_opportunityarea\` l
        JOIN
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
        ON
            s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
            AND l.visionid = "${vision}"
            AND ST_WITHIN(s.geometry, l.geometry)
        GROUP BY storeid
    `;
    var cityQuery = `
        SELECT 
            total_population AS citytotalpop,
            population_density AS citypopdensity,
            SAFE_DIVIDE(gender_m, gender_m+gender_f) AS citymaleratio,
            SAFE_DIVIDE(gender_f, gender_m+gender_f) AS cityfemaleratio,
            medianage AS citymedianage,
            wealth_index AS citypurchasingpower,
            sport_market_size AS citysportmarketsize,
            mall_count AS citymallcount,
            competitor_count AS citycompetitorcount,
            sportscomplex_count AS citysportcomplexcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
        WHERE 
            visionid = "${vision}"
    `;
    var localFxRateQuery = `
        SELECT
            rate_2025 AS local_currency_rate
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.FX_rates\`
        WHERE UPPER(country) = UPPER("${country}")
    `;

    var updateEstimateTOQuery = ``;
    var vmodel = "INDIA_TIER_1_MODEL4";
    var brandscore = 50;
    if (vision === "91511") {
        brandscore = 48;
        vmodel = "INDIA_TIER_1_MODEL4";
    } else if (vision === "20674") {
        brandscore = 50;
        vmodel = "INDIA_TIER_1_MODEL4";
    } else if (vision === "93076") {
        brandscore = 53;
        vmodel = "INDIA_TIER_1_MODEL5";
    } else if (vision === "83238") {
        brandscore = 53;
        vmodel = "INDIA_TIER_1_MODEL5";
    } else if (vision === "79296") {
        brandscore = 54;
        vmodel = "INDIA_TIER_1_MODEL5";
    } else if (vision === "93074") {
        brandscore = 56;
        vmodel = "INDIA_TIER_1_MODEL5";
    } else if (vision === "14506") {
        brandscore = 57;
        vmodel = "INDIA_TIER_1_MODEL4";
    } else if (vision === "9933") {
        brandscore = 58;
        vmodel = "INDIA_TIER_1_MODEL4";
    } else if (vision === "79729") {
        brandscore = 50;
        vmodel = "INDIA_TIER_1_MODEL4";
    }
    for (const [currsalesareaclass, modelname] of Object.entries(modelMapping[vmodel])) {
        var storesQuery = `
            SELECT 
                storeid AS store_code,
                sales_area AS sales_area_class,
                CASE WHEN sales_area = "<= 200" THEN CAST(200 AS NUMERIC)
                    WHEN sales_area = "201 - 500" THEN CAST(400 AS NUMERIC)
                    WHEN sales_area = "501 - 1000" THEN CAST(800 AS NUMERIC)
                    WHEN sales_area = "1001 - 2000" THEN CAST(1500 AS NUMERIC)
                    WHEN sales_area = "2001 - 3000" THEN CAST(2500 AS NUMERIC)
                    WHEN sales_area = "3001 - 4000" THEN CAST(3500 AS NUMERIC)
                    WHEN sales_area = "> 4000" THEN CAST(4500 AS NUMERIC)
                    ELSE CAST(1500 AS NUMERIC) END AS sales_area,
                CASE WHEN store_format = "Click & Collect/ Satellite" THEN "Click & Collect/ Satellite"
                    WHEN store_format = "Stand alone - Leased /Platform" THEN "Stand alone"
                    WHEN store_format = "Stand alone - Owned / Platform" THEN "Stand alone"
                    WHEN store_format = "Mall - Leased / Captor" THEN "Mall"
                    WHEN store_format = "Mall - Owned / Captor" THEN "Mall"
                    WHEN store_format = "Fit Out - Leased /Platform" THEN "Fit Out"
                    WHEN store_format = "Fit Out - Owned / Platform" THEN "Fit Out"
                    WHEN store_format = "Franchise" THEN "Franchise"
                    ELSE "Others" END AS store_format,
                2 AS store_age
            FROM 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
            WHERE
                s.visionid = "${visionid}" AND s.storeid IN (${storeids.join(",")})
                AND sales_area = "${currsalesareaclass}"
        `;
        var storeCount = await executeSQL({ 
            credentials, 
            query: storesQuery, 
            connection: process.env.REACT_APP_CONNECTION_NAME, 
            opts: { cache: 'reload' } 
        });
        if (storeCount.length === 0) {
            continue;
        }
        updateEstimateTOQuery = `${updateEstimateTOQuery}
            UPDATE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_storelocations\` s
            SET s.estimated_to = e.predictto
            FROM
            (
                SELECT store_code, CAST((predicted_annual_turnover) / local_currency_rate AS NUMERIC) AS predictto
                FROM
                    ML.PREDICT(MODEL \`${modelname}\`, (
                        SELECT
                            3000 AS distance,
                            store_details.store_code,
                            "${vision}" AS visionid,
                            UPPER("${city}") AS city,
                            store_details.store_format,
                            store_details.store_age,
                            store_details.sales_area,
                            store_details.sales_area_class,
                            ${brandscore} AS brandscore,
                            demo.totalpop,
                            CAST(demo.purchasingpowerpercapita AS NUMERIC) AS purchasingpowerpercapita,
                            demo.popdensity,
                            demo.medianage,
                            SAFE_DIVIDE(gender.popmale, (gender.popmale + gender.popfemale)) AS maleratio,
                            SAFE_DIVIDE(gender.popfemale, (gender.popmale + gender.popfemale)) AS femaleratio,
                            IFNULL(trainstationcount, 0) AS num_trainstations,
                            IFNULL(metrostationcount, 0) AS num_metrostations,
                            IFNULL(mallcount, 0) AS num_malls,
                            IFNULL(competitorcount, 0) AS num_competitors,
                            IFNULL(sportclubcount, 0) AS num_sportfacilities,
                            IFNULL(schoolcount, 0) AS num_schools,
                            IFNULL(universitycount, 0) AS num_university,
                            IFNULL(retailcount, 0) AS num_retails,
                            IFNULL(stadiumcount, 0) AS num_stadiums,
                            IFNULL(pumacount, 0) AS num_puma,
                            IFNULL(skechercount, 0) AS num_skecher,
                            IFNULL(adidascount, 0) AS num_adidas,
                            IFNULL(reebokcount, 0) AS num_reebok,
                            IFNULL(nikecount, 0) AS num_nike,
                            IFNULL(gymcount, 0) AS num_gym,
                            IFNULL(cricketcount, 0) AS num_cricket,
                            IFNULL(swimmingcount, 0) AS num_swimming,
                            IFNULL(footballcount, 0) AS num_football,
                            IFNULL(badmintoncount, 0) AS num_badminton,
                            IFNULL(tenniscount, 0) AS num_tennis,
                            IFNULL(golfcount, 0) AS num_golf,
                            IFNULL(basketballcount, 0) AS num_basketball,  
                            segment.total_respondent,
                            segment.percent_premiumletes,
                            segment.percent_expertletes,
                            segment.percent_aspireletes,
                            segment.percent_valueexercisers,
                            segment.percent_wiselyactives,
                            segment.percent_promoactives,
                            segment.percent_easyactives,
                            livingareatotalpop,
                            livingareapopdensity,
                            livingareapurchasingpower,
                            livingareafoottraffic,
                            livingareabasketsize,
                            livingareasportmarketsize,
                            oppareatotalpop,
                            oppareapopdensity,
                            oppareapurchasingpower,
                            oppareafoottraffic,
                            oppareabasketsize,
                            oppareasportmarketsize,
                            citytotalpop,
                            citypopdensity,
                            citymaleratio,
                            cityfemaleratio,
                            citymedianage,
                            CAST((citypurchasingpower) AS NUMERIC) AS citypurchasingpower,
                            CAST((citysportmarketsize) AS NUMERIC) AS citysportmarketsize,
                            citymallcount,
                            citycompetitorcount,
                            citysportcomplexcount,
                            local_currency_rate,
                        FROM
                            (${storesQuery}) store_details
                        LEFT JOIN
                            (${demographyQuery}) demo
                        ON demo.store_code = store_details.store_code
                        LEFT JOIN
                            (${genderQuery}) gender
                        ON gender.store_code = store_details.store_code
                        LEFT JOIN
                            (${trainstationQuery}) poi_trainstation
                        ON poi_trainstation.store_code = store_details.store_code
                        LEFT JOIN
                            (${metrostationQuery}) poi_metrostation
                        ON poi_metrostation.store_code = store_details.store_code
                        LEFT JOIN
                            (${mallQuery}) poi_mall
                        ON poi_mall.store_code = store_details.store_code
                        LEFT JOIN
                            (${competitorQuery}) poi_competitors
                        ON poi_competitors.store_code = store_details.store_code
                        LEFT JOIN
                            (${sportsclubQuery}) poi_sportclub
                        ON poi_sportclub.store_code = store_details.store_code
                        LEFT JOIN
                            (${schoolQuery}) poi_school
                        ON poi_school.store_code = store_details.store_code
                        LEFT JOIN
                            (${universityQuery}) poi_university
                        ON poi_university.store_code = store_details.store_code
                        LEFT JOIN
                            (${retailQuery}) poi_retail
                        ON poi_retail.store_code = store_details.store_code
                        LEFT JOIN
                            (${stadiumQuery}) poi_stadium
                        ON poi_stadium.store_code = store_details.store_code
                        LEFT JOIN
                            (${segmentQuery}) segment
                        ON segment.store_code = store_details.store_code
                        LEFT JOIN
                            (${livingAreaQuery}) livingarea
                        ON livingarea.store_code = store_details.store_code
                        LEFT JOIN
                            (${oppAreaQuery}) opparea
                        ON opparea.store_code = store_details.store_code
                        CROSS JOIN
                            (${cityQuery}) cityboundary
                        CROSS JOIN
                            (${localFxRateQuery}) localfx
                
                ))
            ) e
            WHERE e.store_code = s.storeid AND s.visionid = "${visionid}";
        `;
    }
    //console.log(updateEstimateTOQuery);
    await createQueryJob(updateEstimateTOQuery, credentials);
}

export const arearange = {
    "<= 200": 200,
    "201 - 500": 400,
    "501 - 1000": 800,
    "1001 - 2000": 1500,
    "2001 - 3000": 2500,
    "3001 - 4000": 3500,
    "> 4000": 4500
};
export const storeformats = [
    '"Franchise"',
    '"Pop up"',
    '"Mall"', 
    '"Click & Collect/ Satellite"', 
    '"Fit Out"',
    '"Stand alone"'
];
export const storeagerange = Array(15).fill(1).map((x, y) => x + y);

export async function runTOEstimationModel(credentials, model, country, city, brandscore, boundary) {
    var preparePolyfillH3Query = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`;
        CREATE TABLE \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\` AS (
            SELECT 
                h3,
                \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_CENTER(h3) AS centerpoint
            FROM
                UNNEST(
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_POLYFILL(
                        ST_GEOGFROMTEXT('${boundary.properties.geometry}')
                    , 7)
                ) AS h3
        );
    `;
    await createQueryJob(preparePolyfillH3Query, credentials);
    var polyfillH3Query = `
        SELECT 
            h3,
            \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
            \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_CENTER(h3) AS centerpoint
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;

    var arealist = [];
    var arearangelist = [];
    for (const [key, value] of Object.entries(arearange)) {
        arealist.push(value);
        arearangelist.push(`"${key}"`);
    }
    // var salesareaQuery = `
    //     SELECT
    //         sales_area
    //     FROM
    //         UNNEST(
    //             [${arealist}]
    //         ) AS sales_area
    // `;
    // var salesareaclassQuery = `
    //     SELECT
    //         sales_area_class
    //     FROM
    //         UNNEST(
    //             [${arearangelist}]
    //         ) AS sales_area_class
    // `;
    var storeFormatQuery = `
        SELECT
            store_format
        FROM
            UNNEST(
                [${storeformats}]
            ) AS store_format
    `;

    var prepareDemographyTableQuery = `
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`;
        CALL \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.ENRICH_POLYGONS(
            R'''
                SELECT 
                    ST_BUFFER(centerpoint, 3000) AS geom,
                    h3,
                    ST_AREA(ST_BUFFER(centerpoint, 3000)) AS geomarea
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
            ''',
            'geom',
            R'''
                SELECT
                    \`${process.env.REACT_APP_CARTO_REGION_NAME}.${process.env.REACT_APP_SCHEMA_NAME}\`.H3_BOUNDARY(h3) AS geom,
                    total_population_sum AS total_population,
                    purchasingpowerpercapita_avg AS purchasingpowerpercapita,
                    agebelow15pct_avg AS agebelow15pct,
                    age15to59pct_avg AS age15to59pct,
                    age60abovepct_avg AS age60abovepct
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.demography_h3_${country}_${city}\`
            ''',
            'geom',
            [('total_population', 'sum'), ('purchasingpowerpercapita', 'avg'), ('agebelow15pct', 'avg'), ('age15to59pct', 'avg'), ('age60abovepct', 'avg')],
            ['\`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`']
        );
    `;
    await createQueryJob(prepareDemographyTableQuery, credentials);
    var demographyQuery = `
        SELECT 
            h3, 
            total_population_sum AS totalpop, 
            total_population_sum * 1000000 / geomarea AS popdensity, 
            purchasingpowerpercapita_avg AS purchasingpowerpercapita,
            CAST(ROUND(CAST((7*agebelow15pct_avg) + (37*age15to59pct_avg) + (60*age60abovepct_avg) AS NUMERIC),2) AS INT64) AS medianage
        FROM   
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`
    `;
    
    var checkGenderQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.COLUMNS\`
        WHERE
            table_name IN ("gender_${vision}_${country}_${city}", "gender_${country}_${city}", "gender_${country}", "demography_${vision}_${country}_${city}", "demography_${country}_${city}")
            AND column_name IN ('pop_female', 'pop_male');
    `;
    var gendertabletouse = ``;
    var output = await executeSQL({ credentials, query: checkGenderQuery, connection: process.env.REACT_APP_CONNECTION_NAME, opts: { cache: 'reload' } });
    var gendertables = [`gender_${vision}_${country}_${city}`, `gender_${country}_${city}`, `gender_${country}`, `demography_${vision}_${country}_${city}`, `demography_${country}_${city}`];
    for (var i = 0; i < gendertables.length; i++) {
        for (var j = 0; j < output.length; j++) {
            if (output[j].table_name === gendertables[i]) {
                gendertabletouse = gendertables[i];
                break;
            }
        }
    }
    var genderQuery;
    if (gendertabletouse.length === 0) {
        genderQuery = `
            SELECT
                h3 AS h3,
                0 AS popmale,
                0 AS popfemale,
            FROM 
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
        `;
    } else {
        genderQuery = `
            SELECT 
                s.h3,
                SUM(a.pop_male * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popmale,
                SUM(a.pop_female * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS popfemale
            FROM
                \`vision-tool-4w7t.carto.${gendertabletouse}\` a
            JOIN
                (
                    SELECT 
                        ST_BUFFER(centerpoint, 3000) AS geom,
                        h3
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY s.h3
        `;
    }

    var poitables = [
        `"POI_Trainstation_${vision}_${country}_${city}"`,
        `"POI_Metrolines_${vision}_${country}_${city}"`,
        `"POI_Malls_${vision}_${country}_${city}"`,
        `"POI_Competitors_${vision}_${country}_${city}"`,
        `"POI_Sportsclubs_${vision}_${country}_${city}"`,
        `"POI_School_${vision}_${country}_${city}"`,
        `"POI_University_${vision}_${country}_${city}"`,
        `"POI_HighStreetretail_${vision}_${country}_${city}"`,
        `"POI_Stadium_${vision}_${country}_${city}"`,
    ];
    var checkPOIQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name IN (${poitables.join(", ")});
    `;
    var pois = await executeSQL({ 
        credentials, 
        query: checkPOIQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var trainstationQuery = `
        SELECT
            h3,
            0 AS trainstationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var metrostationQuery = `
        SELECT
            h3,
            0 AS metrostationcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var mallQuery = `
        SELECT
            h3,
            0 AS mallcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var competitorQuery = `
        SELECT
            h3,
            0 AS competitorcount,
            0 AS pumacount,
            0 AS skechercount,
            0 AS adidascount,
            0 AS reebokcount,
            0 AS nikecount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var sportsclubQuery = `
        SELECT
            h3,
            0 AS sportclubcount,
            0 AS gymcount,
            0 AS cricketcount,
            0 AS swimmingcount,
            0 AS footballcount,
            0 AS badmintoncount,
            0 AS tenniscount,
            0 AS golfcount,
            0 AS basketballcount,
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var schoolQuery = `
        SELECT
            h3,
            0 AS schoolcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var universityQuery = `
        SELECT
            h3,
            0 AS universitycount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var retailQuery = `
        SELECT
            h3,
            0 AS retailcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    var stadiumQuery = `
        SELECT
            h3,
            0 AS stadiumcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    for (var i = 0; i < pois.length; i++) {
        if (pois[i].table_name === `POI_Trainstation_${vision}_${country}_${city}`) {
            trainstationQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS trainstationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Trainstation_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_Metrolines_${vision}_${country}_${city}`) {
            metrostationQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS metrostationcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Metrolines_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_Malls_${vision}_${country}_${city}`) {
            mallQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS mallcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Malls_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_School_${vision}_${country}_${city}`) {
            schoolQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS schoolcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_School_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_University_${vision}_${country}_${city}`) {
            universityQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS universitycount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_University_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_Competitors_${vision}_${country}_${city}`) {
            competitorQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS competitorcount,
                    SUM( IF(UPPER(name) LIKE "%PUMA%", 1, 0) ) AS pumacount,
                    SUM( IF(UPPER(name) LIKE "%SKECHERS%" OR UPPER(name) LIKE "%SKETCHERS%", 1, 0) ) AS skechercount,
                    SUM( IF(UPPER(name) LIKE "%ADIDAS%" OR UPPER(name) LIKE "%ADDIDAS%", 1, 0) ) AS adidascount,
                    SUM( IF(UPPER(name) LIKE "%REEBOK%", 1, 0) ) AS reebokcount,
                    SUM( IF(UPPER(name) LIKE "%NIKE%", 1, 0) ) AS nikecount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Competitors_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_Sportsclubs_${vision}_${country}_${city}`) {
            sportsclubQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS sportclubcount,
                    SUM( IF(UPPER(name) LIKE "%GYM%" OR UPPER(name) LIKE "%FITNESS%", 1, 0) ) AS gymcount,
                    SUM( IF(UPPER(name) LIKE "%CRICKET%", 1, 0) ) AS cricketcount,
                    SUM( IF(UPPER(name) LIKE "%SWIMMING%" OR UPPER(name) LIKE "%POOL%", 1, 0) ) AS swimmingcount,
                    SUM( IF(UPPER(name) LIKE "%FOOTBALL%", 1, 0) ) AS footballcount,
                    SUM( IF(UPPER(name) LIKE "%BADMINTON%", 1, 0) ) AS badmintoncount,
                    SUM( IF(UPPER(name) LIKE "%TENNIS%", 1, 0) ) AS tenniscount,
                    SUM( IF(UPPER(name) LIKE "%GOLF%", 1, 0) ) AS golfcount,
                    SUM( IF(UPPER(name) LIKE "%BASKETBALL%", 1, 0) ) AS basketballcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Sportsclubs_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_HighStreetretail_${vision}_${country}_${city}`) {
            retailQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS retailcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_HighStreetretail_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
        if (pois[i].table_name === `POI_Stadium_${vision}_${country}_${city}`) {
            stadiumQuery = `
                SELECT
                    s.h3,
                    COUNT(1) AS stadiumcount
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.POI_Stadium_${vision}_${country}_${city}\` a
                JOIN
                    (
                        SELECT 
                            centerpoint AS geom,
                            h3
                        FROM
                            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                    ) s
                ON
                    ST_DISTANCE(a.geom, s.geom) <= 3000
                GROUP BY
                    s.h3
            `;
        }
    }

    var checkSegmentQuery = `
        SELECT DISTINCT
            table_name
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.INFORMATION_SCHEMA.TABLES\`
        WHERE
            table_name = "segments_${vision}_${country}_${city}";
    `;
    var segmentExists = await executeSQL({ 
        credentials, 
        query: checkSegmentQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    var segmentQuery = `
        SELECT 
            h3,
            CAST(0 AS NUMERIC) AS total_respondent,
            CAST(0 AS NUMERIC) AS percent_premiumletes,
            CAST(0 AS NUMERIC) AS percent_expertletes,
            CAST(0 AS NUMERIC) AS percent_aspireletes,
            CAST(0 AS NUMERIC) AS percent_valueexercisers,
            CAST(0 AS NUMERIC) AS percent_wiselyactives,
            CAST(0 AS NUMERIC) AS percent_promoactives,
            CAST(0 AS NUMERIC) AS percent_easyactives,
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
    `;
    if (segmentExists.length > 0) {
        segmentQuery = `
            SELECT 
                h3,
                CAST(SUM(a.total_respondent * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS total_respondent,
                CAST(SUM(SAFE_DIVIDE(a.premium_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_premiumletes,
                CAST(SUM(SAFE_DIVIDE(a.expert_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_expertletes,
                CAST(SUM(SAFE_DIVIDE(a.aspire_letes, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_aspireletes,
                CAST(SUM(SAFE_DIVIDE(a.value_exercisers, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_valueexercisers,
                CAST(SUM(SAFE_DIVIDE(a.wisely_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_wiselyactives,
                CAST(SUM(SAFE_DIVIDE(a.promo_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_promoactives,
                CAST(SUM(SAFE_DIVIDE(a.easy_actives, a.total_respondent) * SAFE_DIVIDE(ST_AREA(ST_INTERSECTION(a.geom, s.geom)), ST_AREA(a.geom))) AS NUMERIC) AS percent_easyactives,
            FROM
                \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.segments_${vision}_${country}_${city}\` a
            JOIN
                (
                    SELECT 
                        ST_BUFFER(centerpoint, 3000) AS geom,
                        h3
                    FROM
                        \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
                ) s
            ON ST_INTERSECTS(s.geom, a.geom)
            GROUP BY
                h3
        `;
    }
    
    var livingAreaQuery = `
        SELECT 
            h3,
            MAX(total_population) AS livingareatotalpop,
            MAX(population_density) AS livingareapopdensity,
            MAX(wealth_index) AS livingareapurchasingpower,
            MAX(avg_traffic) AS livingareafoottraffic,
            MAX(avg_basket) AS livingareabasketsize,
            MAX(sport_market_size) AS livingareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_livingarea\` l
        JOIN
            (
                SELECT 
                    centerpoint AS geom,
                    h3
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
            ) s
        ON
            l.visionid = "${vision}"
            AND ST_WITHIN(s.geom, l.geometry)
        GROUP BY h3
    `;
    var oppAreaQuery = `
        SELECT 
            h3,
            MAX(total_population) AS oppareatotalpop,
            MAX(population_density) AS oppareapopdensity,
            MAX(wealth_index) AS oppareapurchasingpower,
            MAX(avg_traffic) AS oppareafoottraffic,
            MAX(avg_basket) AS oppareabasketsize,
            MAX(sport_market_size) AS oppareasportmarketsize
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_opportunityarea\` l
        JOIN
            (
                SELECT 
                    centerpoint AS geom,
                    h3
                FROM
                    \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`
            ) s
        ON
            l.visionid = "${vision}"
            AND ST_WITHIN(s.geom, l.geometry)
        GROUP BY h3
    `;
    var cityQuery = `
        SELECT 
            total_population AS citytotalpop,
            population_density AS citypopdensity,
            SAFE_DIVIDE(gender_m, gender_m+gender_f) AS citymaleratio,
            SAFE_DIVIDE(gender_f, gender_m+gender_f) AS cityfemaleratio,
            medianage AS citymedianage,
            wealth_index AS citypurchasingpower,
            sport_market_size AS citysportmarketsize,
            mall_count AS citymallcount,
            competitor_count AS citycompetitorcount,
            sportscomplex_count AS citysportcomplexcount
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_cityboundary\`
        WHERE 
            visionid = "${vision}"
    `;
    var localFxRateQuery = `
        SELECT
            rate_2025 AS local_currency_rate
        FROM
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.FX_rates\`
        WHERE UPPER(country) = UPPER("${country}")
    `;
    localFxRateQuery = `
        SELECT
            CAST(0.0113636 AS NUMERIC) AS local_currency_rate
    `;

    var insertResultQuery = ``;
    for (const [currsalesareaclass, modelname] of Object.entries(modelMapping[model])) {
        insertResultQuery = `${insertResultQuery}
            INSERT INTO \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\` (
                SELECT
                    "${model}" AS modelname,
                    UPPER("${country}") AS country,
                    UPPER("${city}") AS city,
                    h3,
                    geom,
                    sales_area,
                    store_format,
                    store_age,
                    brandscore,
                    distance,
                    CASE WHEN "${model}" = "INDIA_TIER_1_CITIES" THEN CAST(ABS(predicted_annual_turnover * 1000 / local_currency_rate) AS NUMERIC)
                        ELSE CAST(ABS(predicted_annual_turnover / local_currency_rate) AS NUMERIC) END AS predicted_annual_turnover,
                    CURRENT_TIMESTAMP() AS updatedon
                FROM
                    ML.PREDICT(MODEL \`${modelname}\`, (
                        SELECT
                            h3details.h3,
                            h3details.geom,
                            storeformat.store_format,
                            2 AS store_age,
                            ${arearange[currsalesareaclass]} AS sales_area,
                            "${currsalesareaclass}" AS sales_area_class,
                            ${brandscore} AS brandscore,
                            3000 AS distance,
                            demo.totalpop,
                            CAST(demo.purchasingpowerpercapita AS NUMERIC) AS purchasingpowerpercapita,
                            demo.popdensity,
                            demo.medianage,
                            SAFE_DIVIDE(gender.popmale, (gender.popmale + gender.popfemale)) AS maleratio,
                            SAFE_DIVIDE(gender.popfemale, (gender.popmale + gender.popfemale)) AS femaleratio,
                            IFNULL(trainstationcount, 0) AS num_trainstations,
                            IFNULL(metrostationcount, 0) AS num_metrostations,
                            IFNULL(mallcount, 0) AS num_malls,
                            IFNULL(competitorcount, 0) AS num_competitors,
                            IFNULL(sportclubcount, 0) AS num_sportfacilities,
                            IFNULL(schoolcount, 0) AS num_schools,
                            IFNULL(universitycount, 0) AS num_university,
                            IFNULL(retailcount, 0) AS num_retails,
                            IFNULL(stadiumcount, 0) AS num_stadiums,
                            IFNULL(pumacount, 0) AS num_puma,
                            IFNULL(skechercount, 0) AS num_skecher,
                            IFNULL(adidascount, 0) AS num_adidas,
                            IFNULL(reebokcount, 0) AS num_reebok,
                            IFNULL(nikecount, 0) AS num_nike,
                            IFNULL(gymcount, 0) AS num_gym,
                            IFNULL(cricketcount, 0) AS num_cricket,
                            IFNULL(swimmingcount, 0) AS num_swimming,
                            IFNULL(footballcount, 0) AS num_football,
                            IFNULL(badmintoncount, 0) AS num_badminton,
                            IFNULL(tenniscount, 0) AS num_tennis,
                            IFNULL(golfcount, 0) AS num_golf,
                            IFNULL(basketballcount, 0) AS num_basketball,  
                            segment.total_respondent,
                            segment.percent_premiumletes,
                            segment.percent_expertletes,
                            segment.percent_aspireletes,
                            segment.percent_valueexercisers,
                            segment.percent_wiselyactives,
                            segment.percent_promoactives,
                            segment.percent_easyactives,
                            livingareatotalpop,
                            livingareapopdensity,
                            livingareapurchasingpower,
                            livingareafoottraffic,
                            livingareabasketsize,
                            livingareasportmarketsize,
                            oppareatotalpop,
                            oppareapopdensity,
                            oppareapurchasingpower,
                            oppareafoottraffic,
                            oppareabasketsize,
                            oppareasportmarketsize,
                            citytotalpop,
                            citypopdensity,
                            citymaleratio,
                            cityfemaleratio,
                            citymedianage,
                            CAST((citypurchasingpower) AS NUMERIC) AS citypurchasingpower,
                            CAST((citysportmarketsize) AS NUMERIC) AS citysportmarketsize,
                            citymallcount,
                            citycompetitorcount,
                            citysportcomplexcount,
                            local_currency_rate,
                        FROM
                            (${polyfillH3Query}) h3details
                        CROSS JOIN
                            (${storeFormatQuery}) storeformat
                        LEFT JOIN
                            (${demographyQuery}) demo
                        ON demo.h3 = h3details.h3
                        LEFT JOIN
                            (${genderQuery}) gender
                        ON gender.h3 = h3details.h3
                        LEFT JOIN
                            (${trainstationQuery}) poi_trainstation
                        ON poi_trainstation.h3 = h3details.h3
                        LEFT JOIN
                            (${metrostationQuery}) poi_metrostation
                        ON poi_metrostation.h3 = h3details.h3
                        LEFT JOIN
                            (${mallQuery}) poi_mall
                        ON poi_mall.h3 = h3details.h3
                        LEFT JOIN
                            (${competitorQuery}) poi_competitors
                        ON poi_competitors.h3 = h3details.h3
                        LEFT JOIN
                            (${sportsclubQuery}) poi_sportclub
                        ON poi_sportclub.h3 = h3details.h3
                        LEFT JOIN
                            (${schoolQuery}) poi_school
                        ON poi_school.h3 = h3details.h3
                        LEFT JOIN
                            (${universityQuery}) poi_university
                        ON poi_university.h3 = h3details.h3
                        LEFT JOIN
                            (${retailQuery}) poi_retail
                        ON poi_retail.h3 = h3details.h3
                        LEFT JOIN
                            (${stadiumQuery}) poi_stadium
                        ON poi_stadium.h3 = h3details.h3
                        LEFT JOIN
                            (${segmentQuery}) segment
                        ON segment.h3 = h3details.h3
                        LEFT JOIN
                            (${livingAreaQuery}) livingarea
                        ON livingarea.h3 = h3details.h3
                        LEFT JOIN
                            (${oppAreaQuery}) opparea
                        ON opparea.h3 = h3details.h3
                        CROSS JOIN
                            (${cityQuery}) cityboundary
                        CROSS JOIN
                            (${localFxRateQuery}) localfx
                    ))
            );
        `;
    }

    var toEstimationQuery = `
        DELETE FROM \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\`
        WHERE modelname = "${model}" AND UPPER(country) = UPPER("${country}") AND UPPER(city) = UPPER("${city}");
        ${insertResultQuery}
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mldemography_${vision}\`;
        DROP TABLE IF EXISTS \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.temp_mlpolyfillh3_${vision}\`;
    `;
    await createQueryJob(toEstimationQuery, credentials);
    var result = await checkTOEstimationExists(credentials, model, country, city);
    return (result);
}

export async function checkTOEstimationExists(credentials, model, country, city) {
    var resultQuery = `
        SELECT 
            FORMAT_DATE('%d-%m-%Y %I:%M%p CET', DATETIME(MIN(updatedon), "Europe/Paris")) AS updatedon
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\` 
        WHERE 
            modelname = "${model}" 
            AND UPPER(country) = UPPER("${country}") 
            AND UPPER(city) = UPPER("${city}")
    `;
    var toResults = await executeSQL({ 
        credentials, 
        query: resultQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    if (toResults.length === 0) {
        return null;
    } else if (Date.parse(toResults[0].updatedon) === 0) {
        return null;
    } else {
        return toResults[0].updatedon;
    }
}

export async function fetchAllTOEstimate(credentials, model, country, city) {
    var fetchAllQuery = `
        SELECT 
            h3,
            store_format,
            sales_area,
            AVG(predicted_annual_turnover) AS predicted_annual_turnover
        FROM 
            \`${process.env.REACT_APP_PROJECT_NAME}.${process.env.REACT_APP_SCHEMA_NAME}.fvd_toestimationresult\`
        WHERE
            modelname = "${model}"
            AND UPPER(city) = UPPER("${city}")
            AND UPPER(country) = UPPER("${country}")
        GROUP BY 
            h3, 
            store_format,
            sales_area
        ORDER BY
            h3 DESC,
            predicted_annual_turnover DESC
    `;
    var allResults = await executeSQL({ 
        credentials, 
        query: fetchAllQuery, 
        connection: process.env.REACT_APP_CONNECTION_NAME, 
        opts: { cache: 'reload' } 
    });
    console.log(allResults);
    var allValue = {};
    for (var i = 0; i < allResults.length; i++) {
        var h3Value = allValue[allResults[i].h3];
        if (!h3Value) {
            h3Value = {};
        }
        var storeFormatValue = h3Value["storeformat"];
        if (!storeFormatValue) {
            storeFormatValue = {};
        }
        if (allResults[i].store_format !== "Franchise" && allResults[i].store_format !== "Others") {
            if (storeFormatValue[allResults[i].store_format]) {
                storeFormatValue[allResults[i].store_format] = storeFormatValue[allResults[i].store_format] + (allResults[i].predicted_annual_turnover / storeformats.length);
            } else {
                storeFormatValue[allResults[i].store_format] = (allResults[i].predicted_annual_turnover / storeformats.length);
            }
            h3Value["storeformat"] = storeFormatValue;
        }
        var salesAreaValue = h3Value["salesarea"];
        if (!salesAreaValue) {
            salesAreaValue = {};
        }
        var areaclass;
        for (const [key, value] of Object.entries(arearange)) {
            if (value === allResults[i].sales_area) {
                areaclass = key;
                break;
            }
        }
        if (areaclass) {
            if (salesAreaValue[areaclass]) {
                salesAreaValue[areaclass] = salesAreaValue[areaclass] + (allResults[i].predicted_annual_turnover / Object.keys(arearange).length);
            } else {
                salesAreaValue[areaclass] = (allResults[i].predicted_annual_turnover / Object.keys(arearange).length);
            }
        }
        h3Value["salesarea"] = salesAreaValue;
        if (allResults[i].store_format !== "Franchise" && allResults[i].store_format !== "Others") {
            var storeAndAreaValue = h3Value["storeandarea"];
            if (!storeAndAreaValue) {
                storeAndAreaValue = [];
            }
            storeAndAreaValue.push({"store_format": allResults[i].store_format, "sales_area": areaclass, "estimated_TO": allResults[i].predicted_annual_turnover});
            h3Value["storeandarea"] = storeAndAreaValue;
        }
        allValue[allResults[i].h3] = h3Value;
    }
    return allValue;
}