import { useSelector, useDispatch } from 'react-redux';
import { updateLayer } from '@carto/react-redux';
import htmlForFeature from 'utils/htmlForFeature';
import { LEGEND_TYPES } from '@carto/react-ui';
import { useCallback } from 'react';
import LabelledPolygonLayer from './LabelledPolygonLayer';
import * as turf from '@turf/turf';
import colors from 'assets/config/colors.json';
import colorscheme from 'assets/config/colorSchemes.json';

export const LIVING_AREA_LAYER_ID = 'livingAreaLayer';

const defaultLivingAreaLayerConfig = {
  id: LIVING_AREA_LAYER_ID,
  title: 'Living Area Score',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.6,
  linecolor: colors["Black"],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  labelled: true,
  labelsize: 12,
  labellinelimit: 10,
  colorVOC: false,
  textcolor: colors["Black"],
  legend: {
    collapsed: false,
    attr: "score",
    type: LEGEND_TYPES.BINS,
    labels: [15, 30, 45, 60, 75, 90, 100],
    colorscheme: "LivingAreaDefault",
    colors: colorscheme["LivingAreaDefault"]
  }
};

var livAreaLayerConfig = defaultLivingAreaLayerConfig;

export function getLivingAreaLayerConfig() {
  return livAreaLayerConfig;
};

export function setLivingAreaLayerConfig(newConfig=false) {
  if (newConfig === false) {
    livAreaLayerConfig = defaultLivingAreaLayerConfig;
  } else {
    livAreaLayerConfig = newConfig;
  }
}

export default function LivingAreaLayer() {
  const dispatch = useDispatch();
  const { livingAreaLayer } = useSelector((state) => state.carto.layers);
  const livingArea = useSelector((state) => state.polygon.livingArea);
  const selectedId = useSelector((state) => state.polygon.selectedPolygonId);

  const getFilledColor = useCallback(
    (object) => {
      if (livingAreaLayer) {
        if (livAreaLayerConfig.colorVOC) {
          var currColor = [255, 255, 255];
          for (var i = 0; i < livAreaLayerConfig.legend.labels.length; i++) {
            var currLabel = livAreaLayerConfig.legend.labels[i].toLowerCase().replaceAll('-', '_');
            if (object.properties[livAreaLayerConfig.legend.attr] && currLabel === object.properties[livAreaLayerConfig.legend.attr].toLowerCase().replaceAll('-', '_')) {
              currColor = livAreaLayerConfig.legend.colors[i];
            }
          }
          return currColor;
        } else {
          for (var i = 0; i < livAreaLayerConfig.legend.labels.length; i++) {
            if (object.properties[livAreaLayerConfig.legend.attr] <= livAreaLayerConfig.legend.labels[i]) {
              return livAreaLayerConfig.legend.colors[i];
            } 
          }
          return livAreaLayerConfig.legend.colors[livAreaLayerConfig.legend.labels.length-1];
        }
      }
    }, [livingAreaLayer]
  );
  const getLinesColor = useCallback(
    (object) => {
      if (livingAreaLayer) {
	      if (object.properties.id !== null && object.properties.id === selectedId) {
          return colors["SelectedBoundaryDefault"];
	      }
	      return livAreaLayerConfig.linecolor;
      }
    }, [livingAreaLayer]
  );
  const getTexts = useCallback(
    (object) => {
      if (livingAreaLayer) {
        if (livAreaLayerConfig.labelled) {
          var finalstring = "";
          var textstring = object.properties.livingareaname;
          if (object.properties.rank) {
            textstring = `(${object.properties.rank})`;
          } 
          while (textstring.length > livAreaLayerConfig.labellinelimit) {
            if (finalstring.length > 0) {
              finalstring = finalstring + "\n";
            }
            finalstring = finalstring + textstring.substring(0, livAreaLayerConfig.labellinelimit);
            textstring = textstring.substring(livAreaLayerConfig.labellinelimit, textstring.length);
          }
          if (finalstring.length > 0) {
            finalstring = finalstring + "\n";
          }
          finalstring = finalstring + textstring;
          return finalstring;
        } else {
          return;
        }
      }
    }, [livingAreaLayer]
  );
  const getTextPosition = useCallback((object) => {
    if (livingAreaLayer) {
      const centerofMass = turf.centerOfMass(object).geometry.coordinates;
      return centerofMass;
    }
  }, [livingAreaLayer]);

  if (livingAreaLayer && livingArea) {
    return new LabelledPolygonLayer({
      id: LIVING_AREA_LAYER_ID,
      data: livingArea,
      pickable: true,
      filled: livAreaLayerConfig.filled,
      lineWidthScale: 20,
      lineWidthMinPixels: livAreaLayerConfig.linewidth,
      getFillColor: getFilledColor,
      opacity: livAreaLayerConfig.opacity,
      getLineColor: getLinesColor,
      getLineWidth: 2,
      getText: getTexts,
      getTextSize: livAreaLayerConfig.labelsize,
      getTextColor: livAreaLayerConfig.textcolor,
      getPosition: getTextPosition,
      updateTriggers: {
        getFillColor: selectedId,
        getLineColor: selectedId,
        getFillColor: livAreaLayerConfig,
        getFillColor: livAreaLayerConfig.legend,
        pointRadiusMinPixels: livAreaLayerConfig,
        getLineColor: livAreaLayerConfig.linecolor,
        lineWidthMinPixels: livAreaLayerConfig,
        opacity: livAreaLayerConfig,
        getTextSize: livAreaLayerConfig.labelsize,
        getTextColor: livAreaLayerConfig,
        getText: [livAreaLayerConfig.labelled, livAreaLayerConfig.labellinelimit],
        getTextSize: livAreaLayerConfig.labellinelimit,
        onHover: livAreaLayerConfig.legend,
      },
      onDataLoad: (data) => {
        dispatch(
          updateLayer({
            id: LIVING_AREA_LAYER_ID,
            layerAttributes: { ...livAreaLayerConfig },
          })
        );
      },
      onHover: (info) => {
        if (info?.object) {
	        let {geometry, id, enriched, createdat, updatedat, visionid, name, ...object} = info.object.properties;
          var display = {...info.object};
          display.properties = object;
          for (let d in display.properties) {
	          if (Number.isInteger(display.properties[d])) {
              display.properties[d] = Math.round(display.properties[d]*100) / 100;
	          }
          }
          if (livAreaLayerConfig.colorVOC) {
            var htmltext = `<strong>Name</strong>: ${info.object.properties.livingareaname}` 
                  + `<br/><strong>Top Segment</strong>: ${info.object.properties.top_segment}`;
            for (let d in display.properties) {
              for (var i = 0; i < livAreaLayerConfig.legend.labels.length; i++) {
                if (d === `${livAreaLayerConfig.legend.labels[i].toLowerCase().replaceAll('-', '_')}_segment`) {
                  htmltext = htmltext + `<br/><strong>${livAreaLayerConfig.legend.labels[i]}</strong>: ${Math.round(display.properties[d]*1000/display.properties.total_segment_respondent, 2)/10 + "%"}`
                }
              }
            }
            info.object = {
              html: htmltext,
              style: {},
            };
          } else {
            info.object = {
              html: "<strong>Name</strong>: " + info.object.properties.livingareaname 
              + "<br/><strong>Rank</strong>: " + info.object.properties.rank 
              + "<br/><strong>Score</strong>: " + info.object.properties.score,
              style: {},
            };
          }
          
        }
      },
    });
  }
}
