import { useSelector, useDispatch } from 'react-redux';
import { updateLayer } from '@carto/react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { useCallback } from 'react';
import { LEGEND_TYPES } from '@carto/react-ui';
import colorScheme from 'assets/config/colorSchemes.json';

export const CUSTOMERPERFORMANCE_LAYER_ID = 'customerperformanceLayer';

const defaultCustomerperformanceLayerConfig = {
  id: CUSTOMERPERFORMANCE_LAYER_ID,
  title: 'Customer Performance Sports Layer',
  visible: false,
  switchable: false,
  filled: true,
  opacity: 0.4,
  linecolor: [0, 0, 0],
  linewidth: 1,
  pointradius: 2,
  showOpacityControl: false,
  legend: {
    collapsed: false,
    type: LEGEND_TYPES.CATEGORY,
    labels: [],
    colors: colorScheme["TopSportsDefault"]
  }
};

var customerperformanceLayerConfig = defaultCustomerperformanceLayerConfig;

export function getCustomerperformanceLayerConfig() {
  return customerperformanceLayerConfig;
};

export function setCustomerperformanceLayerConfig(newConfig=false) {
  if (newConfig === false) {
    customerperformanceLayerConfig = defaultCustomerperformanceLayerConfig;
  } else {
    customerperformanceLayerConfig = newConfig;
  }
}

export default function CustomerPerformanceLayer() {
    const dispatch = useDispatch();
    const { customerperformanceLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, customerperformanceLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    const getFilledColor = useCallback(
        (object) => {
            if (customerperformanceLayer) {
                var currColor = [0, 0, 0];
                for (var i = 0; i < customerperformanceLayerConfig.legend.labels.length; i++) {
                    var currLabel = customerperformanceLayerConfig.legend.labels[i];
                    if (currLabel === object.properties.topsport1) {
                        currColor = customerperformanceLayerConfig.legend.colors[i - Math.round(i/customerperformanceLayerConfig.legend.colors.length)];
                    }
                }
                return currColor;
            }
        }, [customerperformanceLayer]
    );

    if (customerperformanceLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: CUSTOMERPERFORMANCE_LAYER_ID,
            filled: customerperformanceLayerConfig.filled,
            getFillColor: getFilledColor,
            opacity : customerperformanceLayerConfig.opacity,
            pointRadiusMinPixels: customerperformanceLayerConfig.pointradius,
            headers: {'cache-control': `max-age=${process.env.REACT_APP_LAYER_CACHE_MAX_AGE}`,},
            getLineColor: customerperformanceLayerConfig.linecolor,
            lineWidthMinPixels: customerperformanceLayerConfig.linewidth,
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({
                        id: CUSTOMERPERFORMANCE_LAYER_ID,
                        layerAttributes: { ...customerperformanceLayerConfig },
                    })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            updateTriggers: {
                getFillColor: customerperformanceLayerConfig,
                getFillColor: customerperformanceLayerConfig.legend,
                getLineColor: customerperformanceLayerConfig,
                opacity: customerperformanceLayerConfig,
            },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: "<strong>Postcode</strong>: " + info.object.properties.postcode
                            + `<br/><strong>Total TO</strong>: ${parseFloat(info.object.properties.total_to).toFixed(0)} (${parseFloat(info.object.properties.physical_to * 100 / info.object.properties.total_to).toFixed(2)}% : ${parseFloat(info.object.properties.digital_to * 100 / info.object.properties.total_to).toFixed(2)}%) ` 
                            + `<br/><strong>Total Customers</strong>: ${parseFloat(info.object.properties.total_customer).toFixed(0)} (${parseFloat(info.object.properties.physical_customer * 100 / info.object.properties.total_customer).toFixed(2)}% : ${parseFloat(info.object.properties.digital_customer * 100 / info.object.properties.total_customer).toFixed(2)}%) `
                            + `<br/><strong>Mean Spend EUR</strong>: ${parseFloat(info.object.properties.mean_spend).toFixed(2)} (${parseFloat(info.object.properties.mean_physical_spend).toFixed(2)} : ${parseFloat(info.object.properties.mean_digital_spend).toFixed(2)}) `
                            + `<br/><strong>(1)</strong>: ${info.object.properties.topsport1} (${info.object.properties.topsport1count}, ${parseFloat(info.object.properties.topsport1to).toFixed(0)})`
                            + `<br/><strong>(2)</strong>: ${info.object.properties.topsport2} (${info.object.properties.topsport2count}, ${parseFloat(info.object.properties.topsport2to).toFixed(0)})`
                            + `<br/><strong>(3)</strong>: ${info.object.properties.topsport3} (${info.object.properties.topsport3count}, ${parseFloat(info.object.properties.topsport3to).toFixed(0)})`
                            + `<br/><strong>(4)</strong>: ${info.object.properties.topsport4} (${info.object.properties.topsport4count}, ${parseFloat(info.object.properties.topsport4to).toFixed(0)})`
                            + `<br/><strong>(5)</strong>: ${info.object.properties.topsport5} (${info.object.properties.topsport5count}, ${parseFloat(info.object.properties.topsport5to).toFixed(0)})`
                            + `<br/><strong>(6)</strong>: ${info.object.properties.topsport6} (${info.object.properties.topsport6count}, ${parseFloat(info.object.properties.topsport6to).toFixed(0)})`
                            + `<br/><strong>(7)</strong>: ${info.object.properties.topsport7} (${info.object.properties.topsport7count}, ${parseFloat(info.object.properties.topsport7to).toFixed(0)})`
                            + `<br/><strong>(8)</strong>: ${info.object.properties.topsport8} (${info.object.properties.topsport8count}, ${parseFloat(info.object.properties.topsport8to).toFixed(0)})`
                            + `<br/><strong>(9)</strong>: ${info.object.properties.topsport9} (${info.object.properties.topsport9count}, ${parseFloat(info.object.properties.topsport9to).toFixed(0)})`
                            + `<br/><strong>(10)</strong>: ${info.object.properties.topsport10} (${info.object.properties.topsport10count}, ${parseFloat(info.object.properties.topsport10to).toFixed(0)})`
                            + `<br/><strong>(11)</strong>: ${info.object.properties.topsport11} (${info.object.properties.topsport11count}, ${parseFloat(info.object.properties.topsport11to).toFixed(0)})`
                            + `<br/><strong>(12)</strong>: ${info.object.properties.topsport12} (${info.object.properties.topsport12count}, ${parseFloat(info.object.properties.topsport12to).toFixed(0)})`
                            + `<br/><strong>(13)</strong>: ${info.object.properties.topsport13} (${info.object.properties.topsport13count}, ${parseFloat(info.object.properties.topsport13to).toFixed(0)})`
                            + `<br/><strong>(14)</strong>: ${info.object.properties.topsport14} (${info.object.properties.topsport14count}, ${parseFloat(info.object.properties.topsport14to).toFixed(0)})`
                            + `<br/><strong>(15)</strong>: ${info.object.properties.topsport15} (${info.object.properties.topsport15count}, ${parseFloat(info.object.properties.topsport15to).toFixed(0)})`
                        ,
                      style: {},
                    };
                }
            },
        });
    }
}
